import React from "react";
import {translate} from "../utils/function";
import Campaign from "./Campaign";
import Loader from "./layouts/Loader";

const CampaignList = ({title, data, loading,onSortByChange}) => {
    // const [searchParams] = useSearchParams(window.location.href);
    const handleSortByChange = (e) => {
        // navigate(`/?sort-by=${e.target.value}`);
        onSortByChange(e.target.value)

    }
    return (
        <section className="card view_all p-3">
            <div className="row mb-4 justify-content-center align-items-center">
                <div className="text-start col-md-8 col-6">
                    <span className="h4"><strong>{title}</strong></span>
                </div>
                <div className="text-end col-md-4 col-6">
                    <select name="" id="sort-by" className="form-control-lg form-select" onChange={handleSortByChange}>
                        <option value="newest">{translate('Newest')}</option>
                        <option value="popular">{translate('Popular')}</option>
                    </select>
                </div>
            </div>
            <div className="">

                {(() => {
                    if (data && data.length > 0) {
                        return (<div className="row justify-content-center justify-content-md-start">
                                {data.map((row) => {
                                    return (
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4 " key={row.id}>
                                            <Campaign data={row}/>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    } else {
                        return (
                            <div className="text-center">
                                <h3>{translate("No Data Found")}</h3>
                            </div>
                        )
                    }
                })()}

                {
                    loading ? <div className="text-center"><Loader/></div> : null
                }
            </div>
        </section>
    );
}

export default CampaignList;