const StaticSectionPage = ({title, data}) => {
    return (
        <section className="mb-5 card static-card">
            <h4 className="ms-4 mt-4">
                <strong>{title}</strong>
            </h4>
            <div className="ms-4 me-4">
                <hr/>
            </div>
            <div className="row text-justify m-3">
                <div className="col-12 " dangerouslySetInnerHTML={{__html: data}}></div>
            </div>
        </section>
    );
}

export default StaticSectionPage;