import {createSelector, createSlice} from "@reduxjs/toolkit";
import moment from "moment";

import {apiCallBegan} from "./apiActions";
import {getLanguageLabelAPI, getLanguagesAPI} from "../utils/api";
import {store} from "./configureStore";

let initialState = {
    list: [],
    loading: false,
    lastFetch: null,
    currentLanguage: {
        code: null,
        name: null
    },
    currentLanguageLabels: {
        loading: true,
        data: {},
        lastFetch: null,
    },
};
const slice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        languagesRequested: (languages, action) => {
            languages.loading = true;
        },
        languagesReceived: (languages, action) => {
            let payload = action.payload.data;
            // Add All as region on top of object
            payload.unshift({
                id: null,
                name: "English",
                code: "EN",
            })
            languages.list = payload;
            languages.loading = false;
            languages.lastFetch = Date.now();
        },
        languagesRequestFailed: (languages, action) => {
            languages.loading = false;
        },
        languageChanged: (languages, action) => {
            languages.currentLanguage.code = action.payload.code;
            languages.currentLanguage.name = action.payload.name;
        },
        languageLabelRequested: (languages, action) => {
            languages.currentLanguageLabels.loading = true;
        },
        languageLabelsReceived: (languages, action) => {
            languages.currentLanguageLabels.data = action.payload.data;
            languages.currentLanguageLabels.loading = false;
            languages.currentLanguageLabels.lastFetch = Date.now();
        },
        languageLabelRequestFailed: (languages, action) => {
            languages.currentLanguageLabels.loading = true;
        },
    }
});

export const {languagesRequested, languagesReceived, languagesRequestFailed, languageChanged, languageLabelRequested, languageLabelsReceived, languageLabelRequestFailed} = slice.actions;
export default slice.reducer;

// API Calls
export const loadLanguages = (id = null, search = null, onSuccess, onError, onStart) => {
    const {lastFetch} = store.getState().languages;
    const diffInMinutes = moment().diff(moment(lastFetch), 'minutes');
    // If API data is fetched within last 10 minutes then don't call the API again
    if (diffInMinutes < 10) return false;
    store.dispatch(apiCallBegan({
        ...getLanguagesAPI(id, search),
        displayToast: false,
        onStartDispatch: languagesRequested.type,
        onSuccessDispatch: languagesReceived.type,
        onErrorDispatch: languagesRequestFailed.type,
        onStart,
        onSuccess,
        onError
    }))
};

export const setCurrentLanguage = (name, code) => {
    store.dispatch(languageChanged({name, code}));
};

export const loadLanguageLabels = (code, onSuccess, onError, onStart) => {
    const {lastFetch} = store.getState().languages.currentLanguageLabels;
    const {code: currentCode} = store.getState().languages.currentLanguage;
    const diffInMinutes = moment().diff(moment(lastFetch), 'minutes');
    // If API data is fetched within last 10 minutes then don't call the API again

    if (code === currentCode) {
        if (diffInMinutes < 10) {
            return false;
        }
    }

    store.dispatch(apiCallBegan({
        ...getLanguageLabelAPI(code),
        displayToast: false,
        onStartDispatch: languageLabelRequested.type,
        onSuccessDispatch: languageLabelsReceived.type,
        onErrorDispatch: languageLabelRequestFailed.type,
        onStart,
        onSuccess,
        onError
    }))
};

// Selector Functions
export const selectLanguages = createSelector(
    state => state.languages,
    languages => languages.list,
)

export const selectCurrentLanguage = createSelector(
    state => state.languages.currentLanguage,
    languages => languages,
)

export const selectCurrentLanguageLabels = createSelector(
    state => state.languages.currentLanguageLabels.data,
    languages => languages
)