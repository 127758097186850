import { IoLogoFacebook } from "react-icons/io5";
import {socialMediaLogin} from "../store/user";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {translate} from "../utils/function";
import FirebaseConfig from "../utils/firebase";

const SocialMediaAuthentication = () => {
    const firebase = FirebaseConfig();
    const {state} = useLocation();
    const navigate = useNavigate();
    const handleSocialMediaLogin = async (provider) => {
        let authProvider = null;
        if (provider === "google") {
            authProvider = firebase.googleProvider;
        } else if (provider === "facebook") {
            authProvider = firebase.facebookprovider;
        }
        await firebase.auth.signInWithPopup(authProvider).then((response) => {
            // if (response.additionalUserInfo.isNewUser) {
            //If new User then show the Update Profile Screen
            // photoURL
            let {uid, email, phoneNumber} = response.user;
            socialMediaLogin(phoneNumber, email, provider, uid, () => {
                state && state.prev ? navigate(`${process.env.PUBLIC_URL + state.prev}`) : navigate(`${process.env.PUBLIC_URL}/`);
            });
        }).catch((error) => {
            toast.error(error.message);
        });
    }
    return (
        <div className="text-center">
            <div className="social-btn row">
                <div className="col-md-6 col-12">
                    <button type="button" className="facebook btn btn-primary btn-floating my-1" onClick={() => handleSocialMediaLogin('facebook')}>
                        <IoLogoFacebook className="me-2" size={16}/>{translate("Continue with Facebook")}
                    </button>
                </div>
                <div className="col-md-6 col-12">
                    <button type="button" className="google btn btn-primary btn-floating my-1" onClick={() => handleSocialMediaLogin('google')}>
                        {/* <FaGoogle className="me-2" size={16} />{translate("Continue with Google")} */}
                        <i className="google-btn fab fa-google fa-3x"></i>{translate("Continue with Google")}
                    </button>
                </div>

            </div>
            <p className="mt-3 or_Data">{translate("OR")}</p>
            {/*<button type="button" className="btn btn-primary btn-floating mx-1 theme-bg-color">*/}
            {/*    <i className="fab fa-twitter"></i> <FaTwitter/>*/}
            {/*</button>*/}

            {/*<button type="button" className="btn btn-primary btn-floating mx-1 theme-bg-color">*/}
            {/*    /!*<i className="fab fa-github"></i>*!/ <FaGithub/>*/}
            {/*</button>*/}
        </div>
    );
}

export default SocialMediaAuthentication;