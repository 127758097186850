import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistor, store as configureStore} from './store/configureStore';
import {BrowserRouter} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/mdb.min.css';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import {Slide, ToastContainer} from "react-toastify";
import {PersistGate} from "redux-persist/integration/react";
import Routes from "./routes";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // <React.StrictMode>
    <Provider store={configureStore}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <ToastContainer
                    position="top-center"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    theme="colored"
                    transition={Slide}/>
                <Routes/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);
