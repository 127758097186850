import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import { FiCamera, FiCopy, FiUsers } from "react-icons/fi";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { getAllCampaigns, supportCampaign } from "../store/campaign";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import {
  dateDifferenceFromNow,
  defaultCampaignImage,
  defaultUserImage,
  isLogin,
  showShortText,
  translate,
} from "../utils/function";
import { MdArrowBackIos, MdArrowForwardIos, MdRefresh } from "react-icons/md";
import { BsShare } from "react-icons/bs";
import { HiOutlineClock } from "react-icons/hi";
import { Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import CommentSection from "../components/CommentSection";
import CampaignSlider from "../components/CampaignSlider";
import { useSelector } from "react-redux";
import { selectCurrentRegion } from "../store/regions";
import { ImFacebook2 } from "react-icons/im";
import { FaTwitterSquare, FaWhatsapp } from "react-icons/fa";
import CanvasContext from "../context/canvas-context";

const CampaignDetails = () => {
  const [steps, setSteps] = useState(1);
  const [canvasHeight, setCanvasHeight] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [progress, setProgress] = useState(20);
  const [downloadProgress, setDownloadProgress] = useState(1);
  const [campaign, setCampaign] = useState();
  const [loader, setLoader] = useState(true);
  const [showReadMore, setShowReadMore] = useState(true);
  const [temp, setTemp] = useState();
  const { Image: fabImage } = fabric;
  const inputFile = useRef(null);
  const { editor, onReady } = useFabricJSEditor();
  const textAreaRef = useRef(null);
  const currentRegion = useSelector(selectCurrentRegion);
  const [regionCampaigns, setRegionCampaigns] = useState();
  const [showModal, setShowModal] = useState(false);
  const [readySeconds, setReadySeconds] = useState(1);
  const [canvas1, setCanvas1] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  useEffect(() => {
    setCanvas1(editor);
  }, []);

  //3rd step message is missing here by intention as 3rd step has special logic
  const stepMessages = new Map([
    [1, translate("Pick a Photo for your chosen frame")],
    [2, translate("Pick a Photo for your chosen frame")],
    [4, translate("Copy the caption")],
    [5, translate("Share your photo")],
  ]);
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    getAllCampaigns(
      { slug },
      (response) => {
        setCampaign(response.data.data[0]);
        setLoader(false);
        window.scrollTo(0, 0);
      },
      () => {
        navigate("/");
      }
    );
  }, [slug]);

  useEffect(() => {
    //Fetch Region wise Campaign
    getAllCampaigns({ region_id: currentRegion.id }, (response) =>
      setRegionCampaigns(response.data.data)
    );
  }, [currentRegion]);

  const handleZoom = (e) => {
    const newZoom = parseFloat(e.target.value);
    setZoomLevel(newZoom);

    const objects = editor?.canvas.getObjects();
    const innerImage = objects.find(
      (obj) => obj.type === "image" && !obj.overlay
    );

    if (innerImage) {
      const originalWidth = innerImage.width;
      const originalHeight = innerImage.height;
      const canvasWidth = editor?.canvas.getWidth();
      const canvasHeight = editor?.canvas.getHeight();

      innerImage.set({
        scaleX: newZoom,
        scaleY: newZoom,
      });

      // Center the image
      innerImage.centerH();
      innerImage.centerV();

      // Constrain the image to the canvas
      const scaledWidth = originalWidth * newZoom;
      const scaledHeight = originalHeight * newZoom;

      if (scaledWidth < canvasWidth) {
        innerImage.set({ left: (canvasWidth - scaledWidth) / 2 });
      }
      if (scaledHeight < canvasHeight) {
        innerImage.set({ top: (canvasHeight - scaledHeight) / 2 });
      }

      editor?.canvas.renderAll();
    }
  };

  useEffect(() => {
    if (campaign && editor?.canvas) {
      // Set the overlay image (outer image)
      fabImage.fromURL(
        `${campaign.canvas_url}`,
        (oImg) => {
          console.log("width : ", editor?.canvas.getWidth());
          console.log("height : ", editor?.canvas.getHeight());
          oImg.scaleToWidth(editor?.canvas.getWidth());
          oImg.scaleToHeight(editor?.canvas.getHeight());
          editor?.canvas.setOverlayImage(
            oImg,
            () => {
              editor?.canvas.renderAll();
            },
            {
              overlay: true,
            }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }

    console.log("called");
    setCanvasHeight(editor?.canvas.getWidth());
  }, [editor, fabImage, size, campaign]);

  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [originalImageSize, setOriginalImageSize] = useState({ width: 0, height: 0 });


  const calculateCanvasSize = useCallback(() => {
    if (originalImageSize.width && originalImageSize.height) {
      const screenWidth = window.innerWidth;
      let canvasWidth = screenWidth * 0.9; // 90% of screen width
      
      // Max width for larger screens
      const maxWidth = 800;
      if (canvasWidth > maxWidth) canvasWidth = maxWidth;

      const aspectRatio = originalImageSize.height / originalImageSize.width;
      const canvasHeight = canvasWidth * aspectRatio;

      setCanvasSize({ width: canvasWidth, height: canvasHeight });
    }
  }, [originalImageSize]);

  useEffect(() => {
    const handleResize = () => {
      calculateCanvasSize();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateCanvasSize]);

  useEffect(() => {
    if (campaign && editor?.canvas) {
      fabric.Image.fromURL(
        `${campaign.canvas_url}`,
        (img) => {
          setOriginalImageSize({ width: img.width, height: img.height });
          
          calculateCanvasSize();

          editor.canvas.setOverlayImage(
            img,
            () => {
              editor.canvas.renderAll();
            },
            {
              scaleX: editor.canvas.width / img.width,
              scaleY: editor.canvas.height / img.height,
              originX: 'left',
              originY: 'top',
              crossOrigin: 'anonymous'
            }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }
  }, [editor, campaign, calculateCanvasSize]);

  useEffect(() => {
    if (editor?.canvas && canvasSize.width && canvasSize.height) {
      editor.canvas.setWidth(canvasSize.width);
      editor.canvas.setHeight(canvasSize.height);
      editor.canvas.renderAll();
    }
  }, [editor, canvasSize]);

  const uploadImage = (e) => {
    const readerObj = new FileReader();
    readerObj.onload = () => {
      fabImage.fromURL(
        readerObj.result,
        (oImg) => {
          const canvasWidth = editor?.canvas.getWidth();
          const canvasHeight = editor?.canvas.getHeight();

          // Set the image to fit the canvas initially
          const scale = Math.min(
            canvasWidth / oImg.width,
            canvasHeight / oImg.height
          );

          oImg.set({
            scaleX: scale,
            scaleY: scale,
          });

          // Remove any existing inner image
          const existingInnerImage = editor?.canvas
            .getObjects()
            .find((obj) => obj.type === "image" && !obj.overlay);
          if (existingInnerImage) {
            editor?.canvas.remove(existingInnerImage);
          }

          editor?.canvas.add(oImg);
          oImg.center();
          editor?.canvas.renderAll();

          // Reset zoom level
          setZoomLevel(1);
        },
        {
          crossOrigin: "Anonymous",
        }
      );
    };
    changeStep(2);
    if (typeof e.target.files[0] != "undefined") {
      readerObj.readAsDataURL(e.target.files[0]);
    }
  };

  // editor?.canvas.on('mouse:wheel', function (opt) {
  //     // let delta = opt.e.deltaY;
  //     // let pointer = editor?.canvas.getPointer(opt.e);
  //     // let zoom = editor?.canvas.getZoom();
  //     // zoom = zoom + delta / 200;
  //     // if (zoom > 20) zoom = 20;
  //     // if (zoom < 0.01) zoom = 0.01;
  //     // editor?.canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
  //     // opt.e.preventDefault();
  //     // opt.e.stopPropagation();
  //     let sel_obj = editor?.canvas.getActiveObject();
  //
  //     let x = 350 * (parseFloat(10) + 1);
  //     let y = 350 * (parseFloat(10) + 1);
  //
  //     let sel_obj_width = sel_obj.width;
  //     let sel_obj_height = sel_obj.height;
  //
  //     // if (parseFloat(window.width()) <= 767) {
  //     //     x = x * 0.4242424242424242;
  //     //     y = y * 0.4242424242424242;
  //     //
  //     //     sel_obj_width = sel_obj_width * 0.4242424242424242;
  //     //     sel_obj_height = sel_obj_height * 0.4242424242424242;
  //     // }
  //
  //     let center = sel_obj.getCenterPoint();
  //
  //     // sel_obj.set({
  //     //     scaleX: x / sel_obj_width,
  //     //     scaleY: y / sel_obj_height,
  //     //     originX: "center",
  //     //     originY: "center",
  //     //     left: center.x,
  //     //     top: center.y,
  //     // });
  //
  //     editor?.canvas.renderAll();
  // });
  // editor?.canvas.on('object:modified', function (event) {
  //     let image_size = (event.target.width * event.target.scaleX).toFixed(0);
  //     image_size = (image_size / 350) - 1;
  // });
  const saveFile = () => {
    // // Open image in new tab
    // let image = new Image();
    // image.crossOrigin = "anonymous";
    // image.src = editor.canvas.toDataURL();
    // let w = window.open("");
    // w.document.write(image.outerHTML);

    let anchor = document.createElement("a");
    editor?.canvas.setBackgroundColor("#fff");
    anchor.href = editor?.canvas.toDataURL({
      format: "png",
    });
    // setTemp(editor?.canvas.toDataURL({
    //     format: "png",
    // }));
    anchor.download = campaign.title;
    anchor.click();
  };

  const handleChangeReadMore = () => {
    setShowReadMore(!showReadMore);
  };

  const changeStep = (nextStep) => {
    setProgress(nextStep * 20);
    setSteps(nextStep);
  };

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then((r) => {
      toast("Copied", {
        position: "bottom-center",
        className: "theme-bg-color",
        autoClose: 1000,
        toastId: 1,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "color",
      });
    });
  }

  const handleSupportCampaign = () => {
    supportCampaign(campaign.id, () => {
      changeStep(5);
      setCampaign({ ...campaign, supporters: campaign.supporters + 1 });
    });
  };

  const loadAdvertisement = () => {
    let increment = 1;
    let interval_time = 125;
    let seconds = interval_time;
    const interval = setInterval(function () {
      // method to be executed;
      increment += 2.5;
      setDownloadProgress(increment);
      setReadySeconds(Math.floor(seconds / 1000));
      seconds += interval_time;
      if (increment > 100) {
        clearInterval(interval);
      }
    }, interval_time);

    setTemp(
      editor?.canvas.toDataURL({
        format: "png",
      })
    );
  };
  const handleClose = () => setShowModal(false);
  return (
    /* Header Started */
    <div>
      <Header />
      {/*<div className="fluid-container light-theme-bg-color banner-area justify-content-center d-flex align-items-center">*/}
      {/*    <div className="banner-image">*/}
      {/*        <img src={`${process.env.PUBLIC_URL}/assets/img/sample-banner.jpg`} alt="banner"/>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <main className="mt-5">
        <div className="container custom-container-details">
          <div className="row d-flex justify-content-center">
            {state && state.newCampaignMessage ? (
              <div className="row justify-content-center">
                <div
                  className="col-8 alert alert-success theme-bg-color alert-dismissible fade show"
                  role="alert"
                >
                  {translate("Campaign Created Successfully")}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            ) : null}

            <div className="col-12 col-md-12 col-lg-8 col-xl-6">
              <section className="text-center">
                {(() => {
                  if (loader) {
                    return <Loader />;
                  } else {
                    return (
                      <>
                        <div className="card custom bg-light mb-3 camp_details">
                          <div className="row">
                            <div className="mt-3 campaign-detail-user">
                              <img
                                src={campaign.user.image}
                                className="w-100 me-2"
                                alt={campaign.user.full_name}
                                onError={defaultUserImage}
                              />
                            </div>
                            <h5 className="mt-3 theme-color font-weight-bolder text-capitalize">
                              {campaign.user.full_name}
                            </h5>
                            <h1 className="mt-3 text-dark">
                              <strong>{campaign.title}</strong>
                            </h1>
                            <p className="mt-3">
                              {campaign.hashtags ? campaign.hashtags : null}
                            </p>
                            <p className="mt-3 text-center camp_details_title px-4">
                              {showReadMore
                                ? showShortText(campaign.description, 210)
                                : campaign.description}
                            </p>

                            <div>
                              {campaign.description &&
                              campaign.description.length > 210 ? (
                                <div className="d-flex justify-content-end">
                                  <div
                                    className="text-end me-2 col-3 theme-color"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleChangeReadMore}
                                  >
                                    {showReadMore
                                      ? translate("Read More")
                                      : translate("Read Less")}{" "}
                                    <MdArrowForwardIos />
                                  </div>
                                </div>
                              ) : null}
                              <hr className="ms-3 me-3 my-2" />
                            </div>
                          </div>

                          {/*<div>*/}
                          <div className="row justify-content-between ms-1 me-1 camp_details_support">
                            <h5 className="col-md-6 col-sm-6 col-12">
                              <div className="alert alert-theme p-1">
                                <FiUsers size={16} />
                                <small className="ms-1">
                                  {" "}
                                  {campaign.supporters +
                                    " " +
                                    translate("Supporters")}
                                </small>
                              </div>
                            </h5>

                            <h5 className="col-md-6 col-sm-6 col-12">
                              <div className="alert alert-theme p-1">
                                <HiOutlineClock size={16} />
                                <small className="ms-1">
                                  {" "}
                                  {dateDifferenceFromNow(campaign.created_at)}
                                </small>
                              </div>
                            </h5>
                          </div>
                          {/*</div>*/}
                        </div>

                        <div className="card card_details_link">
                          <div className="custom p-2 theme-bg-color">
                            <div className="row align-items-center">
                              <div className="col-10 text-start">
                                {window.location.href}
                              </div>
                              <div className="col-2 text-end">
                                <BsShare
                                  size={23}
                                  onClick={() => setShowModal(true)}
                                  role="button"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="p-3 mt-3 card custom bg-light">
                          <div className="pt-3">
                            <ProgressBar now={progress} />
                          </div>
                          {steps === 3 ? (
                            <div className="light-theme-bg-color mt-3 p-2 rounded">
                              <div className="row align-items-center">
                                <div className="col-sm-10 col-10 custom_col-70">
                                  <div className="inner_content_campaign">
                                    <div className="inner_image_campaign">
                                      <img
                                        src={campaign.image}
                                        alt={campaign.title}
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="ps-2 text-start text-dark">
                                      {readySeconds < 5 ? (
                                        <>
                                          <span className="h5">
                                            {translate("Processing your photo")}{" "}
                                            !
                                          </span>
                                          <br />
                                          <span className="h6">
                                            {translate(
                                              "Please wait for a while"
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="h5">
                                            {translate("Ready to Download")} !
                                          </span>
                                          <br />
                                          <span className="h6 d-none d-sm-block">
                                            {translate(
                                              "Click the Download Button"
                                            )}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2 col-4 custom_col-30 text-center">
                                  <div className="border border-1 rounded-circle border-black py-2 bg-light border-circle">
                                    <span className="h4">
                                      {readySeconds < 5 ? readySeconds : "✅"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="light-theme-bg-color mt-3 p-2 rounded">
                              <span className="theme-color">
                                {stepMessages.get(steps)}
                              </span>
                            </div>
                          )}

                          <div className="pt-1 pb-2">
                            <input
                              className="d-none"
                              ref={inputFile}
                              type="file"
                              onChange={uploadImage}
                            />
                            {/* <div
                              className={`mt-3 row col-1 sample-canvas ${
                                steps > 2 ? "d-none" : null
                              }`}
                              style={{ height: canvasHeight }}
                              data-mdb-ripple-color="light"
                            > */}

<div
        className={`mt-3 sample-canvas ${steps > 2 ? "d-none" : null}`}
        style={{ 
          width: canvasSize.width, 
          height: canvasSize.height,
          maxWidth: '100%',
          margin: '0 auto'
        }}
      >
          {/* <CanvasContext.Provider
                                editor={canvas1}
                                value={"dfsdfdf"}
                              > */}
                                <FabricJSCanvas
                                  className="w-100 h-100 p-0"
                                  onReady={onReady}
                                />
                              {/* </CanvasContext.Provider> */}
        {/* <FabricJSCanvas className="w-100 h-100" onReady={onReady} /> */}
      </div>
                              {/* <CanvasContext.Provider
                                editor={canvas1}
                                value={"dfsdfdf"}
                              >
                                <FabricJSCanvas
                                  className="w-100 h-100 p-0"
                                  onReady={onReady}
                                />
                              </CanvasContext.Provider> */}
                            {/* </div> */}

                            {(() => {
                              // Choose Image
                              if (steps === 1) {
                                return (
                                  <>
                                    {isLogin() ? (
                                      <button
                                        className="btn btn-block btn-primary theme-bg-color mt-4 commom-zindex card_details_download"
                                        onClick={() =>
                                          inputFile.current.click()
                                        }
                                      >
                                        <FiCamera size={24} />
                                        &nbsp;&nbsp;
                                        <b className="h6 mb-0">
                                          {translate("Choose a Photo")}
                                        </b>
                                      </button>
                                    ) : (
                                      <Link
                                        className="btn btn-block btn-primary theme-bg-color mt-3 commom-zindex"
                                        to={"/login"}
                                        state={{ prev: location.pathname }}
                                      >
                                        <b className="h6">
                                          {translate(
                                            "Login to Support this Campaign"
                                          )}
                                        </b>
                                      </Link>
                                    )}
                                  </>
                                );
                                // Next
                              } else if (steps === 2) {
                                return (
                                  <>
                                    <div className="zoom-control mt-3 commom-zindex">
                                      <input
                                        type="range"
                                        min="0.1"
                                        max="3"
                                        step="0.1"
                                        className="w-100"
                                        value={zoomLevel}
                                        onChange={handleZoom}
                                      />
                                      <span>
                                        {Math.round(zoomLevel * 100)}%
                                      </span>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-2 col-3">
                                        <button
                                          className="btn d-flex align-item-center justify-content-center w-auto pt-3 pb-3"
                                          onClick={() =>
                                            inputFile.current.click()
                                          }
                                        >
                                          <FiCamera
                                            size={20}
                                            className="theme-color ms-n1"
                                          />
                                        </button>
                                      </div>
                                      <div className="col-md-10 col-9">
                                        <button
                                          className="btn btn-block btn-primary theme-bg-color p-3 commom-zindex"
                                          onClick={() => {
                                            changeStep(3);
                                            loadAdvertisement();
                                          }}
                                        >
                                          <b className="h6">
                                            {translate("Next")}
                                          </b>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );

                                // Download
                              } else if (steps === 3) {
                                return (
                                  <>
                                    <div
                                      className="commom-zindex bg-image hover-overlay ripple mt-3 sample-canvas light-theme-bg-color rounded-4"
                                      data-mdb-ripple-color="light"
                                    >
                                      <div className="h-100 d-flex justify-content-center align-items-center">
                                        {/*<img src="https://dummyimage.com/300" alt="dummy" className="img-fluid"/>*/}
                                        <img
                                          src={temp}
                                          alt="dummy"
                                          className="img-fluid w-75"
                                        />
                                      </div>
                                    </div>

                                    {downloadProgress > 100 ? (
                                      <div className="row mt-3">
                                        <div className="col-md-3 col-5 backbtn">
                                          <button
                                            className="btn"
                                            onClick={() => changeStep(1)}
                                          >
                                            <b className="h6">
                                              <MdArrowBackIos size={20} />
                                              {translate("Back")}
                                            </b>
                                          </button>
                                        </div>
                                        <div className="col-md-9 col-7">
                                          <button
                                            className="btn btn-block btn-primary theme-bg-color p-3"
                                            onClick={() => {
                                              saveFile();
                                              changeStep(4);
                                            }}
                                          >
                                            <div className="theme-bg-color">
                                              <b className="h6">
                                                {translate("Download")}
                                              </b>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="mt-3 px-4">
                                        <ProgressBar
                                          className="download"
                                          now={downloadProgress}
                                          label={
                                            <div className="h5 d-flex align-items-center justify-content-center mb-0">
                                              {translate("Download")}
                                            </div>
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                );

                                //Done
                              } else if (steps === 4) {
                                return (
                                  <>
                                    <div className="mt-3 sample-canvas h-100">
                                      <div
                                        className="text-start ps-3"
                                        style={{ fontFamily: "monospace" }}
                                      >
                                        <textarea
                                          className="d-none"
                                          ref={textAreaRef}
                                          defaultValue={`\n${
                                            campaign.title
                                          }\n\n\n(${translate(
                                            "Mention 3 of your friends or more here"
                                          )})
                                                                                \n${translate(
                                                                                  "Get yourself this Framitto frame at"
                                                                                )} ${
                                            window.location.href
                                          }
                                                                                \n${translate(
                                                                                  "Don't forget to follow"
                                                                                )} @framitto ${translate(
                                            "for further updates"
                                          )}!
                                                                                \n${
                                                                                  campaign.hashtags
                                                                                } #framitto`}
                                        />
                                        <br></br>
                                        {campaign.title}
                                        <br></br>
                                        <br></br>
                                        <br></br>(
                                        {translate(
                                          "Mention 3 of your friends or more here"
                                        )}
                                        )<br></br>
                                        <br></br>
                                        {translate(
                                          "Get yourself this Framitto frame at"
                                        )}{" "}
                                        {window.location.href}
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        {translate(
                                          "Don't forget to follow"
                                        )}{" "}
                                        @framitto{" "}
                                        {translate("for further updates")}!
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        {campaign.hashtags
                                          ? campaign.hashtags
                                          : null}{" "}
                                        #framitto
                                      </div>
                                    </div>
                                    <div className="row mt-3 flex-wrap">
                                      <div className="col-md-2 col-3">
                                        <button
                                          className="btn p-3"
                                          onClick={() => changeStep(3)}
                                        >
                                          <b className="h6 ms-1">
                                            <MdArrowBackIos size={20} />
                                          </b>
                                        </button>
                                      </div>
                                      <div className="col-md-2 col-3">
                                        <button
                                          className="btn p-3 theme-border"
                                          onClick={() =>
                                            copyToClipboard(
                                              textAreaRef.current.value
                                            )
                                          }
                                        >
                                          <FiCopy
                                            size={20}
                                            className="theme-color"
                                          />
                                        </button>
                                      </div>
                                      <div className="col-md-8 col-6">
                                        <button
                                          className="btn btn-block btn-primary theme-bg-color p-3"
                                          onClick={handleSupportCampaign}
                                        >
                                          <b className="h6">
                                            {translate("Done")}
                                          </b>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              } else if (steps === 5) {
                                return (
                                  <>
                                    <div
                                      className="bg-image hover-overlay ripple mt-3 sample-canvas light-theme-bg-color rounded-4"
                                      data-mdb-ripple-color="light"
                                    >
                                      <div className="d-flex justify-content-center mt-3">
                                        <div className="w-50">
                                          <img
                                            src={temp}
                                            alt="dummy"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="row justify-content-center mt-5"
                                        style={{ fontFamily: "monospace" }}
                                      >
                                        {translate(
                                          "It's Time to support this campaign"
                                        )}
                                        <br></br>
                                        {translate(
                                          "Do share this with your Friends"
                                        )}
                                        <br></br>
                                        {translate("Thank you")}
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-3 col-5">
                                        <button
                                          className="btn p-3"
                                          onClick={() => changeStep(4)}
                                        >
                                          <b className="h6">
                                            <MdArrowBackIos size={20} />
                                            {translate("Back")}
                                          </b>
                                        </button>
                                      </div>
                                      <div className="col-md-9 col-7">
                                        <button
                                          className="btn btn-block btn-primary theme-bg-color p-3"
                                          onClick={() => changeStep(1)}
                                        >
                                          <b className="h6">
                                            <MdRefresh
                                              size={20}
                                              className="mb-1 me-1"
                                            />
                                            {translate("Start Over")}
                                          </b>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })()}
                          </div>
                        </div>

                        {/*Comment Section */}
                        <CommentSection campaign={campaign} />

                        <Modal
                          show={showModal}
                          onHide={handleClose}
                          className="d-flex align-items-center"
                        >
                          <Modal.Body>
                            <div className="text-center my-3">
                              <div className="row d-flex justify-content-center">
                                <div className="col-6">
                                  <img
                                    src={campaign.image}
                                    alt={campaign.title}
                                    className="img-fluid w-100"
                                    onError={defaultCampaignImage}
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <h4 className="font-weight-bolder">
                                  {translate(
                                    "Share this Campaign to Social Media"
                                  )}
                                </h4>
                              </div>
                              <div className="row d-flex justify-content-center mt-3">
                                <a
                                  href={
                                    "https://web.whatsapp.com/send?text=" +
                                    window.location.href
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  className="col-1"
                                >
                                  <i>
                                    <FaWhatsapp size={30} />
                                  </i>
                                </a>
                                <a
                                  href={
                                    "https://twitter.com/intent/tweet?text=" +
                                    window.location.href
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  className="col-1"
                                >
                                  <i>
                                    <FaTwitterSquare
                                      className="twitter-logo-color"
                                      size={30}
                                    />
                                  </i>
                                </a>
                                <a
                                  href={
                                    "http://www.facebook.com/sharer.php?u=" +
                                    window.location.protocol +
                                    "//" +
                                    window.location.hostname +
                                    "&quote=" +
                                    window.location.href
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  className="col-1"
                                >
                                  <i>
                                    <ImFacebook2
                                      className="facebook-logo-color"
                                      size={30}
                                    />
                                  </i>
                                </a>
                              </div>
                              <div className="row mt-3">
                                <h5 className="text-black-50">
                                  {translate(
                                    "Or copy the link to this campaign"
                                  )}
                                </h5>
                              </div>
                              <div
                                className="row mt-2 border p-1 rounded-3 mx-2 border-1"
                                role="button"
                                onClick={() =>
                                  copyToClipboard(window.location.href)
                                }
                              >
                                <div className="col-11 text-start">
                                  {window.location.href}
                                </div>
                                <div className="col-1">
                                  <FiCopy size={22} />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    );
                  }
                })()}
              </section>
            </div>
            <div className=" p-3 pb-1 rounded-2 mt-5 pe-0 pe-md-3">
              <CampaignSlider
                title={translate("Trending in") + " " + currentRegion.name}
                data={regionCampaigns}
                regionID={currentRegion.id}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
export default CampaignDetails;
