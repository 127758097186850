import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {translate} from "../utils/function";
import Header from "../components/layouts/Header";
import {getCollections} from "../store/campaign";
import Footer from "../components/layouts/Footer";
import Slider from "../components/layouts/Slider";
import Loader from "../components/layouts/Loader";
import {useSelector} from "react-redux";
import {selectSettings} from "../store/settings";
import {selectCurrentRegion} from "../store/regions";

const Explore = () => {
    const [collection, setCollection] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loadCollections, setLoadCollections] = useState(false);
    const [lastPage, setLastPage] = useState(null);

    const [page, setPage] = useState(1);
    const settings = useSelector(selectSettings);
    const region = useSelector(selectCurrentRegion);
    useEffect(() => {
        getCollections({region_id: region.id}, (response) => {
            setLoader(false);
            setCollection(response.data.data);
            setLastPage(response.data.last_page);
        }, () => {
            setLoader(false);
        });
    }, [])
    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 50) {
            // you're at the bottom of the page
            if (lastPage !== page) {
                setLoadCollections(true);
                getCollections({region_id: region.id, page: (page + 1)}, (response) => {
                    setPage(page + 1);
                    setCollection(collection.concat(response.data.data))
                    //setLastPage(response.data.data.last_page);
                    setLoadCollections(false);
                })
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener("scroll", handleScroll, true);
    }, [handleScroll])

    return (
        /* Header Started */
        <div onScroll={handleScroll}>
            <Header/> <Slider/>
            <main className="my-5">
                <div className="container">
                    {(() => {
                            if (loader) {
                                return (<div className="text-center"><Loader/></div>);
                            } else {
                                return (
                                    collection.length > 0 ?
                                        <>

                                            <div className="row text-start">
                                                <div className="col-12">
                                                    <span className="h4 font-weight-bolder">{translate("Collections")}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                {
                                                    collection.map((row) => {
                                                        return <div key={row.id} className="col-12 col-md-4 mb-4">
                                                            <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                                                <Link to={`/collection/${row.id}`}> <img src={settings.collection_image} className="img-fluid  collection-image" title={row.name} alt={row.name}/>
                                                                    <div className="collection-title text-white col-md-3"><h3>{row.name}</h3></div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                                {loadCollections ? <div className="text-center"><Loader/></div> : ''}
                                            </div>
                                        </>
                                        :
                                        <div className="text-center">
                                            <h3>{translate("No Data Found")}</h3>
                                        </div>
                                )
                            }
                        }
                    )()}
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Explore;