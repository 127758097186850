import {apiCallBegan} from "./apiActions";
import {checkCampaignURLAvailabilityAPI, createCampaignAPI, deleteCampaignAPI, deleteCommentAPI, getAllCampaignsAPI, getCollectionsAPI, getCommentAPI, getMyCampaignsAPI, reportAPI, storeCommentAPI, supportCampaignAPI, updateCampaignAPI, updateCommentAPI} from "../utils/api";
import {store} from "./configureStore";

// API Calls
export const createCampaign = (title, description, hashtags, image, region_id, slug, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...createCampaignAPI(title, description, hashtags, image, region_id, slug),
        onStart,
        onSuccess,
        onError,
    }));
}

export const getMyCampaigns = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getMyCampaignsAPI(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}

// export const getAllCampaigns = (search, slug, id, region_id, language_id, type, onSuccess, onError, onStart) => {
//     store.dispatch(apiCallBegan({
//         ...getAllCampaignsAPI(search, slug, id, region_id, language_id, type),
//         displayToast: false,
//         onStart,
//         onSuccess,
//         onError,
//     }));
// }
export const getAllCampaigns = ({search = null, slug = null, id = null, region_id = null, language_id = null, type = null, page = null, limit}, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getAllCampaignsAPI(search, slug, id, region_id, language_id, type, page, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}
export const updateCampaign = (id, title, description, hashtags, image, region_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateCampaignAPI(id, title, description, hashtags, image, region_id),
        onStart,
        onSuccess,
        onError,
    }));
}
export const deleteCampaign = (id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...deleteCampaignAPI(id),
        displayToast: true,
        onStart,
        onSuccess,
        onError,
    }));
}

export const getCollections = ({search, id, region_id, page, limit}, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getCollectionsAPI(search, id, region_id, page, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}

export const supportCampaign = (id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...supportCampaignAPI(id),
        displayToast: false,
        onStartDispatched:
        onStart,
        onSuccess,
        onError,
    }));
}

export const checkCampaignURLAvailability = (link, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...checkCampaignURLAvailabilityAPI(link),
        displayToast: false,
        onStartDispatched:
        onStart,
        onSuccess,
        onError,
    }));
}

export const getComment = (campaign_id, page = 1, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getCommentAPI(campaign_id, page),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}

export const storeComment = (campaign_id, comment, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...storeCommentAPI(campaign_id, comment),
        displayToast: true,
        onStart,
        onSuccess,
        onError,
    }));
}

export const updateComment = (id, comment, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateCommentAPI(id, comment),
        displayToast: true,
        onStart,
        onSuccess,
        onError,
    }));
}

export const deleteComment = (id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...deleteCommentAPI(id),
        displayToast: true,
        onStart,
        onSuccess,
        onError,
    }));
}

export const reportComment = (id, reason, sub_reason, description, email, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...reportAPI('comment', id, reason, sub_reason, description, email),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}