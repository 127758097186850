import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {useState} from "react";

import {login} from "../store/user";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {translate} from "../utils/function";
import SocialMediaAuthentication from "../components/SocialMediaAuthentication";
import Loader from "../components/layouts/Loader";
import {toast} from "react-toastify";


const Login = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [showemail,setShowEmail] = useState(false);

    const {state} = useLocation();
    const navigate = useNavigate();
    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        login(data.email, data.password, (response) => {
            state && state.prev ? navigate(`${process.env.PUBLIC_URL + state.prev}`) : navigate(`${process.env.PUBLIC_URL}/`);
            setLoading(false);
            toast.success(response.message, {position: "top-center"});
        }, (error) => {
            setLoading(false);
            toast.error(error, {position: "top-center"});
        });
    }

    const handleChange = (event) => {
        const field_name = event.target.name;
        const field_value = event.target.value;
        if (field_name === "mobile" && event.target.value.length > 16) {
            event.target.value = field_value.slice(0, event.target.maxLength);
            return false;
        }
        setData((values) => ({...values, [field_name]: field_value}));
    };

    const showFielddata = () => {
        setShowEmail(true);
    }

    const handleClick = (e) => {
        e.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate("/Register")
    }


    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="card mb-5 p-3 login_sec">
                        <h4 className="my-4 text-center">
                            {/* <strong>{translate("Login")}</strong> */}
                            <strong>{translate("Welcome to Framitto")} <br />{translate("Please Login to Continue")}</strong>
                        </h4>

                        <div className="row d-flex justify-content-center mb-4">
                            <div className="col-md-9 col-lg-6  mx-auto ">
                                <SocialMediaAuthentication/>
                                <div className="content_data_login">
                                    <p className="theme_para text-center" onClick={() => showFielddata()}>{translate("Sign In With Email")}</p>
                                    {/* <p className="theme_para text-center" onClick={() => showFielddata()}>{translate("SignIn") }</p> */}
                                </div>

                                {
                                    showemail ? <form onSubmit={formSubmit}>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form3Example3">{translate("Email")}</label>
                                        <input type="email" id="email" name="email" className="form-control" placeholder={translate("Email")} onChange={handleChange}/>
                                    </div>

                                    <div className="form-outline mb-3">
                                        <label className="form-label" htmlFor="form3Example4">{translate("Password")}</label>
                                        <input type="password" id="password" name="password" className="form-control" autoComplete="on" placeholder={translate("Password")} onChange={handleChange}/>
                                    </div>
                                    <div className="text-end mb-3">
                                        <Link to="/forgot-password">{translate("Forgot Password")} ?</Link>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block mb-4 theme-bg-color">{!loading ? translate("Login") : <Loader size="sm"/>}</button>
                                        <div className="already_account">
                                            <p className="text-center">{translate("Not on Framitto Yet ?")} <Link onClick={(e) => handleClick(e)} className="btn-color fw-bold">{translate("SignUp")}</Link></p>
                                        </div>

                                    </form>

                                        : null
                                }

                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Login;