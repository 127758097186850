import firebase from "firebase/app";
import {useSelector} from "react-redux";
import {selectSettings} from "../store/settings";

require("firebase/auth");
// require("firebase/firestore");

const FirebaseConfig = () => {
    const settings = useSelector(selectSettings);
    let config;
    if (typeof settings !== "undefined" && typeof settings.firebase !== "undefined") {
        config = {
            apiKey: settings.firebase.apiKey,
            authDomain: settings.firebase.authDomain,
            projectId: settings.firebase.projectId,
            storageBucket: settings.firebase.storageBucket,
            messagingSenderId: settings.firebase.messagingSenderId,
            appId: settings.firebase.appId,
            measurementId: settings.firebase.measurementId,
        }
    }
    let firebaseConfig = {
        ...config
    };
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    } else {
        firebase.app(); // if already initialized, use that one
    }

    const auth = firebase.auth();

    const googleProvider = new firebase.auth.GoogleAuthProvider();

    const facebookprovider = new firebase.auth.FacebookAuthProvider();

    return ({auth, googleProvider, facebookprovider, firebase});
}

export default FirebaseConfig;
