import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {useEffect, useState} from "react";

import {fetchSettings} from "../store/settings";
import StaticPageSection from "../components/StaticPageSection";
import {translate} from "../utils/function";

const ContactUs = () => {
    const [data, setData] = useState();
    useEffect(() => {
        fetchSettings('contact_us', (response) => {
            setData(response.data)
        })
    }, [])
    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <StaticPageSection title={translate("Contact US")} data={data}/>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default ContactUs;