import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import Home from "../pages/Home";
import CampaignDetails from "../pages/CampaignDetails";
import Login from "../pages/Login";
import Register from "../pages/Register";
import MyCampaigns from "../pages/MyCampaigns";
import CollectionDetails from "../pages/CollectionDetails";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Explore from "../pages/Explore";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";
import {useSelector} from "react-redux";
import {selectCurrentLanguageLabels} from "../store/languages";
import EditCampaign from "../pages/EditCampaign";
import ForgotPassword from "../pages/ForgotPassword";
import {Helmet} from "react-helmet";
import {getSettings, selectSettings} from "../store/settings";
import Collections from "../pages/Collections";
import Loader from "../components/layouts/Loader";
import ResetPassword from "../pages/ResetPassword";
function Router() {
    useEffect(() => {
        getSettings();
    }, [])
    useSelector(selectCurrentLanguageLabels);
    const settings = useSelector(selectSettings);
    return (
        typeof settings !== "undefined" && typeof settings.firebase !== "undefined" ?
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{settings.seo_site_title}</title>
                    <meta name="description" content={settings.seo_site_description}/>
                    <meta name="keywords" content={settings.seo_site_keywords}/>
                </Helmet>
                <Routes basename={process.env.PUBLIC_URL}>

                    {/* Public Pages which don't require authentication */}
                    <Route path="/" element={<Home/>}/>


                    {/* Display this routes only after User Authentication */}
                    <Route path="/" element={<PrivateRoute/>}>
                        <Route path="/my-campaigns" element={<MyCampaigns/>}/>
                        <Route path="/campaign/edit/:id" element={<EditCampaign/>}/>
                        <Route path="/my-profile" element={<Profile/>}/>
                        <Route path="/change-password" element={<ChangePassword/>}/>
                    </Route>

                    {/* Display this pages only if user is not Unauthenticated */}
                    <Route path="/" element={<PublicRoute afterLoginAccess={false}/>}>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                    </Route>

                    <Route path="/collection" element={<Collections/>}/>
                    <Route path="/collection/:slug" element={<CollectionDetails/>}/>
                    <Route path="/about-us" element={<AboutUs/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/explore" element={<Explore/>}/>
                    {/*<Route path="/test" element={<FabricDemo/>}/>*/}
                    <Route path="/:slug" element={<CampaignDetails/>}/>

                </Routes>
            </>
            :
            <Loader/>

    );
}

export default Router;
