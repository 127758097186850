import {translate} from "../../utils/function";
import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import {useSelector} from "react-redux";
import {selectSettings} from "../../store/settings";

const Footer = () => {
    const settings = useSelector(selectSettings);
    return (
        <footer className="bg-light-grey  text-lg-start mt-2 footer_sec">
            <div className="container p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-6 mb-4 mb-md-0 footer_logo">
                        <h1 className="theme-color">
                            <img src={settings.horizontal_logo} alt="Logo" className="img-fluid"/>
                        </h1>
                        <p>We help you to create campaigns to support the causes you are passionate about, you believe in</p>
                    </div>

                    <div className="col-lg-3 col-md-3 mb-4 mb-md-0 about_Sec">
                        <h5 className="text-uppercase">{translate("About")}</h5>

                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/about-us"} className="text-dark">{translate("About Us")}</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/contact-us"} className="text-dark">{translate("Contact US")}</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"} className="text-dark">{translate("Privacy Policy")}</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/terms-and-conditions"} className="text-dark">{translate("Terms & Conditions")}</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-3 social_sec">
                        <h5 className="text-uppercase">{translate("We are Social")}</h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="me-3"> <img src={process.env.PUBLIC_URL + "../assets/img/facebook.png"}  width={30} height={30} alt="facebook" /> </a>
                                <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="me-3"> <img src={process.env.PUBLIC_URL + "../assets/img/instagram.png"}  width={30} height={30} alt="instagram"/> </a>
                                <a href="https://www.twitter.com/" target="_blank" rel="noreferrer" className="me-3"> <img src={process.env.PUBLIC_URL + "../assets/img/twitter.png"} width={30} height={30} alt="twitter"/> </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="text-center pb-3">
                © {moment().year()} {translate("All rights Reserved")} |&nbsp;<Link className="text-dark" to="/">Framitto</Link>
            </div>
        </footer>
    );
}

export default Footer;