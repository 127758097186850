import {FaSearch} from "react-icons/fa";
import React, {useEffect, useRef, useState} from "react";
import {HiOutlineClock} from "react-icons/hi";
import {useSelector} from "react-redux";
import {logout, selectUser} from "../../store/user";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {Button, Modal, Nav, Row, Tab} from "react-bootstrap";
import {checkCampaignURLAvailability, createCampaign, getAllCampaigns} from "../../store/campaign";
import {TbLanguageHiragana} from "react-icons/tb";
import {SlArrowDown, SlCompass} from "react-icons/sl";
import {loadRegions, selectCurrentRegion, selectRegions, setCurrentRegion} from "../../store/regions";
import {loadLanguageLabels, loadLanguages, selectCurrentLanguage, selectLanguages, setCurrentLanguage} from "../../store/languages";
import {dateDifferenceFromNow, defaultCampaignImage, defaultUserImage, translate} from "../../utils/function";
import {IoSearchOutline} from "react-icons/io5";
import {components} from 'react-select';
import AsyncSelect from "react-select/async";
import {FiUsers} from "react-icons/fi";
import {BsPlusSquare} from "react-icons/bs";
import {AiOutlineUser} from "react-icons/ai";
import {selectSettings} from "../../store/settings";
import {GrHomeRounded} from "react-icons/gr";
import {RxStack} from "react-icons/rx";

const Header = () => {
    const campaignImageRef = useRef();
    const imageUploadRef = useRef();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        title: "", description: "", hashtags: "", image: "", region_id: "", slug: ""
    });
    const [showLangModal, setShowLangModal] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [url, setUrl] = useState(null);
    const [urlError, setUrlError] = useState({
        error: false,
        message: null
    });
    const {hash} = useLocation();
    const languagePage = useRef();
    const regions = useSelector(selectRegions);
    const currentRegion = useSelector(selectCurrentRegion);
    const languages = useSelector(selectLanguages);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const user = useSelector(selectUser);
    const settings = useSelector(selectSettings);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const handleLogout = (e) => {
        e.preventDefault();
        logout(() => {
            navigate(`${process.env.PUBLIC_URL}/`);
        });
    }
    const handleChange = (event) => {
        const field_name = event.target.name;
        let field_value;
        if (event.target.type === "file") {
            if (event.target.files[0]) {
                campaignImageRef.current.src = URL.createObjectURL(event.target.files[0]);
                setImageSelected(true);
            } else {
                campaignImageRef.current.src = '';
                setImageSelected(false);
            }
            field_value = event.target.files[0];
        } else {
            field_value = event.target.value;
        }
        setData((values) => ({...values, [field_name]: field_value}));
    };

    const handleCreateCampaign = (e) => {
        e.preventDefault();
        let {title, description, hashtags, image, region_id, slug} = data;
        createCampaign(title, description, hashtags, image, region_id, slug, (response) => {
            navigate(`/${response.data.slug}`, {
                state: {newCampaignMessage: true}
            });
        });
    }

    const handleRegionChange = (id, name) => {
        setCurrentRegion(id, name);
        if (currentLanguage.code) {
            setShowLangModal(false);
        } else {
            languagePage.current.click();
        }
    }

    const handleLanguageChange = (name, code) => {
        // Load languages from API
        loadLanguageLabels(code);
        // Set current Language
        setCurrentLanguage(name, code);
        setShowLangModal(false);
    }

    useEffect(() => {
        if (hash === "#campaign") {
            setShow(true);
        }
        loadRegions();
        loadLanguages();
        if (currentRegion.name === null || currentLanguage.name === null) {
            setShowLangModal(true);
        }

    }, [])

    const loadOptions = (search, callback) => {
        getAllCampaigns({search}, response => {
            let data = [];
            response.data.data.map((value) => {
                data.push({value: value.id, slug: `/${value.slug}`, label: value.title, image: value.image, supporters: value.supporters, created_at: value.created_at});
            })
            data.push({slug: `/explore?search=${search}`, label: translate('Show All')});
            callback(data);
        }, () => {
            callback([]);
        })
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <FaSearch/>
            </components.DropdownIndicator>
        );
    };

    const Option = (props) => {
        return (
            <components.Option {...props}>
                {(props.data.value) ?
                    <div className="row">
                        <div className="col-md-1 col-3">
                            <img src={props.data.image} alt={props.data.label} className="w-100" onError={defaultCampaignImage}/>
                        </div>
                        <div className="col-md-11 col-9">
                            <h5>{props.data.label}</h5>
                            <span>
                            <FiUsers/>
                            <small className="ms-2"> {props.data.supporters + " " + translate("Supporters")}</small>
                        </span>
                            <br/>
                            <span>
                            <HiOutlineClock/>
                            <small className="ms-2"> {dateDifferenceFromNow(props.data.created_at)}</small>
                        </span>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <h6>{props.data.label}</h6>
                    </div>
                }
            </components.Option>
        );
    };

    const campaignSelected = (data) => {
        navigate(data.slug);
    }

    // Check URL Availability while creating a campaign
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (url) {
                checkCampaignURLAvailability(url, (response) => {
                    setUrlError({error: false, message: response.message});
                }, (error) => {
                    setUrlError({error: true, message: error});
                });

            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [url]);

    const handleshowlogin = (e) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleloginRedirect = (e) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate('/login');
    }

    const handleregisterRedirect = (e) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate('/register');
    }

    return (
        <React.Fragment>
            <header>

                {/* Upper Small menu started */}
                <nav className="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-block top_menu">

                    <div className="container justify-content-end p-0">
                        <div className="upper-navbar-logo me-auto">
                            <Link className="navbar-brand" to="/">
                                <img src={settings.horizontal_logo} alt="Logo" className="img-fluid d-lg-block d-none"/>
                            </Link>
                        </div>
                        <ul className="navbar-nav flex-row align-items-center">
                            <li className="nav-item me-3 me-lg-3">
                                <Link to="/about-us" className="text-reset">{translate("About")}</Link>
                            </li>

                            <li className="nav-item me-3 me-lg-3">
                                <Link to="/contact-us" className="text-reset">{translate("Contact US")}</Link>
                            </li>
                            <li className="nav-item me-3 me-lg-3 theme-bg-color btn-rounded all_menu">
                                <div role="button" className="text-reset mx-2 all_menu_btn" onClick={() => setShowLangModal(true)}> {currentRegion.name}&nbsp;&nbsp;<SlArrowDown className="mb-1"/></div>
                            </li>
                            <li className="nav-item me-3 me-lg-3 theme-bg-color btn-rounded all_menu language_btn">
                                <div role="button" className="mx-2 language_data all_menu_btn" onClick={() => {
                                    setShowLangModal(true);
                                    setTimeout(() => {
                                        languagePage.current.click();
                                    }, 100)
                                }}>
                                    <TbLanguageHiragana className="me-1" size={20} style={{cursor: "pointer"}}/><small>{currentLanguage.name}</small>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* Upper Small menu ends */}

                {/*Main Menu Started*/}
                <nav className="navbar navbar-expand-lg bg-light logo_sec">
                    <div className="container justify-content-between">
                        {/*px-2*/}
                        <div className="navbar-logo">
                            {/*<Link className="navbar-brand" to="/">Framitto</Link>*/}
                            <Link className="navbar-brand" to="/">
                                <img src={settings.horizontal_logo} alt="Logo" className="img-fluid d-lg-block d-block"/>
                                <img src={settings.favicon} alt="Logo" className="img-fluid d-lg-none d-none"/>
                            </Link>
                            <div className="theme-bg-color rounded-2  d-md-none w-fit-content below-smallest-device">
                                <div role="button" className="text-reset m-2 w-fit-content" onClick={() => setShowLangModal(true)}>
                                    {currentRegion.name}&nbsp;&nbsp;
                                    <SlArrowDown className="mb-1"/>
                                </div>
                            </div>
                        </div>

                        <ul className="d-flex flex-row navbar-nav justify-content-center d-lg-none align-items-center">
                            <li className="nav-item me-2">
                                <IoSearchOutline size={25} role="button" onClick={() => setShowSearch(!showSearch)}/>
                            </li>
                            {/*<li className="nav-item me-2 d-md-block d-none">*/}
                            {/*    <IoNotificationsOutline size={25}/>*/}
                            {/*</li>*/}
                            <li className="theme-bg-color rounded-3 ms-2 me-2 small_device_language">
                                <div role="button" className=" ms-1 me-1" onClick={() => setShowLangModal(true)}>
                                    {currentRegion.name}&nbsp;&nbsp;
                                    <SlArrowDown className="me-1"/>
                                </div>
                            </li>
                            <li className="theme-bg-color rounded-3">
                                <div role="button" className=" ms-1 me-1" onClick={() => {
                                    setShowLangModal(true);
                                    setTimeout(() => {
                                        languagePage.current.click();
                                    }, 100)
                                }}>
                                    <TbLanguageHiragana size={25} style={{cursor: "pointer"}}/>{currentLanguage.code}
                                </div>
                            </li>

                            <button className="navbar-toggler pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </ul>
                        <ul className={`mb-0 col-xxl-7 col-xl-8 col-lg-7 col-md-12 col-12 ps-0 ms-xxl-5 p-sm-0 ${(!showSearch) ? 'd-lg-block d-none' : 'd-block'}`}>
                            <li className="nav-item me-3 me-lg-1 active d-flex justify-content-center w-100">
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    // defaultOptions={true}
                                    components={{DropdownIndicator, Option}}
                                    onChange={campaignSelected}
                                    placeholder={translate("Search Campaigns...")}
                                    className="w-100 search-bar"
                                    // menuIsOpen={true}
                                />
                            </li>
                        </ul>

                        <div className="collapse navbar-collapse row right_content" id="navbarSupportedContent">
                            <ul className="navbar-nav justify-content-end me-3 mobile_view align-items-lg-center">
                                <li className="nav-item me-3 me-lg-1 text-reset">
                                    <Link to="/explore" className="text-reset">
                                        <SlCompass className="me-2" size={30} style={{cursor: "pointer"}}/>
                                        <span className="h5 d-lg-none">{translate("Explore")}</span>
                                    </Link>
                                </li>
                                <li className="nav-item me-3 me-lg-1 text-reset">
                                    <Link to="/collection" className="text-reset">
                                        <RxStack className="me-2" size={30} style={{cursor: "pointer"}}/>
                                        <span className="h5 d-lg-none">{translate("Collections")}</span>
                                    </Link>
                                </li>
                                {/*<li className="nav-item dropdown me-3 me-lg-1">*/}
                                {/*    <div className="dropdown">*/}
                                {/*        <Link to="/" role="button" className="text-reset me-2 dropdown-toggle hidden-arrow" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                {/*            <IoNotificationsOutline size={28} style={{cursor: "pointer"}}/>*/}
                                {/*            <span className="badge rounded-pill badge-notification bg-danger">1</span>*/}
                                {/*            <span className="h5 d-lg-none ps-2">{translate("Notification")}</span>*/}
                                {/*        </Link>*/}
                                {/*        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">*/}
                                {/*            <li><Link to="/" className="dropdown-item">Action</Link></li>*/}
                                {/*            <li><Link to="/" className="dropdown-item">Another action</Link></li>*/}
                                {/*            <li><Link to="/" className="dropdown-item">Something else here</Link></li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}
                                <li className="nav-item me-3 me-lg-1">
                                    {(() => {
                                        if (user.isLogin) {
                                            return (
                                                <Link className="me-2 text-reset" role="button" onClick={handleShow} title={translate('Create Campaign')} to="#">
                                                    <BsPlusSquare size={27}/>
                                                    <span className="h5 ps-2 d-lg-none">{translate("Create Campaign")}</span>
                                                </Link>
                                            );
                                        } else {
                                            return (
                                                <Link to="/login" className="text-reset" state={{prev: '/#campaign'}}><BsPlusSquare className="me-2" size={28} role="button" title={translate('Create Campaign')}/>
                                                    <span className="h5 d-lg-none">{translate("Create Campaign")}</span>
                                                </Link>
                                            );
                                        }
                                    })()}
                                </li>

                                <li className="nav-item dropdown me-3 me-lg-1">
                                    <div className="dropdown">
                                        <Link to="/" role="button" className="text-reset dropdown-toggle hidden-arrow" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {user.isLogin ?
                                                <>
                                                    <img src={user.data.image} alt={user.data.first_name} className="img-thumbnail rounded-circle user-header-image" onError={defaultUserImage}/>
                                                    <span className="h5 ps-2 d-lg-none">{user.data.first_name}</span>
                                                </> :
                                                <AiOutlineUser className="me-2" size={27} style={{cursor: "pointer"}}/>}

                                        </Link>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            {
                                                user.isLogin ?
                                                    <>
                                                        <li><Link to="/my-profile" className="dropdown-item">{translate("My profile")}</Link></li>
                                                        <li><Link to="/change-password" className="dropdown-item">{translate("Change Password")}</Link></li>
                                                        <li><Link to="/my-campaigns" className="dropdown-item">{translate("My Campaigns")}</Link></li>
                                                        <li><Link to="/" className="dropdown-item" onClick={handleLogout}>{translate("Logout")}</Link></li>
                                                    </>
                                                    : <>
                                                        <li><Link to='/login' className="dropdown-item">{translate("Login")}</Link></li>
                                                        <li><Link to="/register" className="dropdown-item">{translate("Register")}</Link></li>
                                                    </>
                                            }
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/*Main Menu Ended*/}

                {/*Mobile Bottom Menu Started */}
                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-bottom d-block d-md-none">
                    <div className="container">
                        <ul className="d-flex flex-row navbar-nav col-12 justify-content-between align-items-center">
                            <li className="nav-item col-2 text-center d-flex justify-content-center">
                                <NavLink to="/" className={({isActive}) => isActive ? "active" : "text-reset"}>
                                    <GrHomeRounded className="text-reset" size="25" title={"Home"}/>
                                </NavLink>
                            </li>
                            <li className="nav-item col-2 text-end  d-flex justify-content-center">
                                <NavLink to="/explore" className={({isActive}) => isActive ? "active" : "text-reset"}>
                                    <SlCompass size="27"/> {/*<h6>Explore</h6>*/}
                                </NavLink>
                            </li>

                            <li className="nav-item col-2 text-center d-flex justify-content-center" onClick={(e) => handleshowlogin(e)}>
                                {user.isLogin ? <BsPlusSquare size="27" className="text-reset" role="button" onClick={handleShow} title={translate('Create Campaign')}/>
                                    :
                                    <Link to="/login" className="text-reset" state={{prev: '/#campaign'}}>
                                        <BsPlusSquare size="27"/> {/*<h6>Explore</h6>*/}
                                    </Link>
                                }

                            </li>
                            <li className="nav-item col-2 text-start  d-flex justify-content-center">
                                <div className="text-reset">
                                    <NavLink to="/collection" className={({isActive}) => isActive ? "active" : "text-reset"}>
                                        <RxStack size="32"/>
                                    </NavLink>

                                </div>
                            </li>

                            <li className="nav-item col-2 text-end dropdown  d-flex justify-content-center">
                                <div className="dropdown dropup">
                                    <Link to="/" role="button" className="text-reset dropdown-toggle hidden-arrow" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiOutlineUser size="27"/>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end text-end custom-menu" aria-labelledby="dropdownMenuButton1">

                                        {
                                            user.isLogin ?
                                                <>
                                                    <li className="p-2 pb-0">{user.data.full_name.trim() !== '' ? user.data.full_name : user.data.email}</li>
                                                    <li>
                                                        <hr className="mt-2 card_hr"/>
                                                    </li>
                                                    <li><NavLink to="/my-profile" className={({isActive}) => isActive ? "active dropdown-item" : "dropdown-item"}>{translate("My profile")}</NavLink></li>
                                                    <li><NavLink to="/change-password" className={({isActive}) => isActive ? "active dropdown-item" : "dropdown-item"}>{translate("Change Password")}</NavLink></li>
                                                    <li><NavLink to="/my-campaigns" className={({isActive}) => isActive ? "active dropdown-item" : "dropdown-item"}>{translate("My Campaigns")}</NavLink></li>
                                                    <li><Link to="/" className="dropdown-item" onClick={handleLogout}>{translate("Logout")}</Link></li>
                                                </>
                                                :
                                                <>
                                                    <li><Link className="dropdown-item " onClick={(e) => handleloginRedirect(e)}>{translate("Login")}</Link></li>
                                                    <li><Link className="dropdown-item " onClick={(e) => handleregisterRedirect(e)}>{translate("Register")}</Link></li>
                                                </>
                                        }
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

                <Modal show={showLangModal} className="d-flex align-items-center w-100" size="lg" aria-labelledby="contained-modal-title-vcenter" backdropClassName="language-modal-backdrop">
                    <div className="theme-border-shadow">
                        <div style={{border: "black 2px solid"}}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className="region_Data">
                                            <Modal.Header closeButton={false}>
                                                <Modal.Title><b className="text-dark">{translate("Choose The Region")}</b></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row text-center">
                                                    {
                                                        regions.map((region) => {
                                                            return (
                                                                <div key={region.id} className="col-12 col-md-6 col-lg-3 mb-3" onClick={() => handleRegionChange(region.id, region.name)}>
                                                                    <div>
                                                                        <div className={`card-body selectable-card ${currentRegion.name === region.name ? "active" : ""}`}>
                                                                            <span>{region.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </Modal.Body>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second" className="language_data">
                                            <Modal.Header closeButton={false}>
                                                <Modal.Title><b className="text-dark">{translate("App Language")}</b></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row text-center">
                                                    {
                                                        languages.map((language) => {
                                                            return (
                                                                <div key={language.id} className="col-12 col-md-6 col-lg-3 mb-3" style={{"cursor": "pointer"}} onClick={() => handleLanguageChange(language.name, language.code)}>
                                                                    <div className={`card-body selectable-card ${currentLanguage.name === language.name ? "active" : ""}`}>
                                                                        {language.name}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </Modal.Body>
                                        </Tab.Pane>
                                    </Tab.Content>

                                    <Nav variant="pills" className="justify-content-center mb-2 dots">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className="dots"></Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link ref={languagePage} eventKey="second" className="dots"></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </Modal>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className="modal-sticky-header">
                        <Modal.Title>{translate("New Campaign")}</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleCreateCampaign} encType="multipart/form-data">
                        <Modal.Body>
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="title">{translate("Title")}</label>
                                <input type="text" id="title" name="title" className="form-control" placeholder={translate("Title")} onChange={handleChange}/>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="description">{translate("Description")} {translate("(Optional)")}</label>
                                <textarea name="description" id="description" rows="3" className="form-control" onChange={handleChange} placeholder={translate("Description")}></textarea>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="hashtags">{translate("Hashtag")} {translate("(Optional)")}</label>
                                <textarea name="hashtags" id="hashtags" rows="2" className="form-control" onChange={handleChange} placeholder={translate("Hashtag")}></textarea>
                            </div>
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="link">{translate("link")}</label>
                                <input type="text" name="slug" id="slug" className="form-control" onInput={(e) => setUrl(e.target.value)} onChange={handleChange} placeholder={translate("Link")}/>
                                <span className={urlError.error ? "text-danger" : "text-success"}>{urlError.message}</span>
                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form3Example4">{translate("Frame")}</label>
                                <div className="image_data">
                                    <div className="col-3 me-3">
                                        <img src="" alt="" onError={defaultCampaignImage} ref={campaignImageRef} className="img-thumbnail w-100"/>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <input type="file" id="image" name="image" className="form-control d-none" ref={imageUploadRef} placeholder={translate("Image")} onChange={handleChange}/>
                                        <button type="button" className="btn btn-primary theme-bg-color" onClick={() => imageUploadRef.current.click()}>{imageSelected ? translate("Change Frame") : translate("Upload Frame")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="form3Example4">{translate("Region")}</label>
                                <select name="region_id" id="region" className="form-control" onChange={handleChange}>
                                    <option value="">--{translate('Select Region')}--</option>
                                    {
                                        regions.map((region) => {
                                            return (<option key={region.id} value={region.id}>{region.name}</option>);
                                        })

                                    }
                                </select>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button type="submit" variant="primary" className="theme-bg-color">{translate("Create")}</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </header>
        </React.Fragment>
    );
}
export default Header;