import {dateDifferenceFromNow, defaultCampaignImage, translate} from "../utils/function";
import React from "react";
import {HiOutlineClock} from "react-icons/hi";
import {FiUsers} from "react-icons/fi";
import {useNavigate} from "react-router-dom";

const Campaign = ({data}) => {

    const navigate = useNavigate();
    return (
        <div className="card w-100 rounded-3 campaign-card card_sec" onClick={() => navigate(`/${data.slug}`, {replace: true})} role="button">
            <div className="campaign-image">
                <img src={data.image} className="img-fluid rounded-top rounded-bottom" alt={data.title} title={data.title} onError={defaultCampaignImage}/>
            </div>


            <div className="row align-items-center">
                <h4 className="card-title text-capitalize text-center campaign-title d-flex align-items-center justify-content-center"><b>{data.title}</b></h4>
                <div>
                    <hr className="ms-2 me-2 mb-3 mt-0 card_hr"/>
                </div>
                <div className="d-flex justify-content-between px-md-3 card-sec-small_device">
                    <div className="alert alert-theme p-1 ps-2 pe-2 w-auto small card_supporters">
                        <FiUsers/>
                        <small > {data.supporters + " " + translate("Supporters")}</small>
                    </div>
                    <div className="alert alert-theme p-1 ps-2 pe-2 w-auto small">
                        <HiOutlineClock/>
                        <small > {dateDifferenceFromNow(data.created_at)}</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Campaign;