import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

import {resetPassword} from "../store/user";
import {translate} from "../utils/function";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../components/layouts/Loader";
import {useState} from "react";

const ResetPassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    function formSubmit(e) {
        setLoading(true);
        e.preventDefault();
        let {token, email, password, password_confirmation} = Object.fromEntries(new FormData(e.target));
        resetPassword(token, email, password, password_confirmation, () => {
            setLoading(false);
            navigate('/login');
        }, () => {
            setLoading(false);
        });
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="card mb-5 p-3 login_sec">
                        <h4 className="mt-3 mb-1 text-center">
                            <strong>{translate("Reset Password")}</strong>
                        </h4>

                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-6">
                                <form onSubmit={formSubmit}>
                                    <input type="hidden" name="token" value={params.token}/>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="email">{translate("Email")}</label>
                                        <input type="email" id="email" name="email" className="form-control" placeholder={translate("Email")}/>
                                    </div>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="password">{translate("Password")}</label>
                                        <input type="password" id="password" name="password" className="form-control" placeholder={translate("Password")}/>
                                    </div>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="password_confirmation">{translate("Confirm Password")}</label>
                                        <input type="password" id="password_confirmation" name="password_confirmation" className="form-control" placeholder={translate("Confirm Password")}/>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block mb-4 theme-bg-color">{!loading ? translate("Submit") : <Loader size="sm"/>}</button>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default ResetPassword;