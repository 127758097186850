import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {useSelector} from "react-redux";
import {defaultCampaignImage, translate} from "../utils/function";
import {selectRegions} from "../store/regions";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getAllCampaigns, updateCampaign} from "../store/campaign";
import Loader from "../components/layouts/Loader";

const EditCampaign = () => {
    const regions = useSelector(selectRegions);
    const campaignImageRef = useRef();
    const imageUploadRef = useRef();
    const [campaign, setCampaign] = useState({
        title: null,
        image: null,
        region_id: null,
        description: null,
    });
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();

    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let {id, title, description, hashtags, image, region_id} = Object.fromEntries(new FormData(e.target));
        updateCampaign(id, title, description, hashtags, image, region_id, () => {
            setLoading(false);
            navigate('/my-campaigns');
        }, () => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getAllCampaigns({id}, (response) => {
            setCampaign(response.data.data[0]);
        })
    }, [])

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            campaignImageRef.current.src = URL.createObjectURL(e.target.files[0]);
        } else {
            campaignImageRef.current.src = '';
        }
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="mb-5">
                        <div className="d-flex justify-content-center">
                            <div className="card p-4 col-11 col-md-8 col-sm-11">
                                <h4 className="mb-2 text-center">
                                    <strong>{translate("Edit Campaign")}</strong>
                                </h4>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-10">
                                        <form onSubmit={formSubmit}>
                                            <input type="hidden" name="id" defaultValue={campaign.id}/>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="title">{translate("Title")}</label>
                                                <input type="text" id="title" name="title" className="form-control" placeholder={translate("Title")} defaultValue={campaign.title}/>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="description">{translate("Description")}</label>
                                                <textarea name="description" id="description" rows="3" className="form-control" placeholder={translate("Description")} defaultValue={campaign.description}></textarea>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="hashtags">{translate("Hashtag")}</label>
                                                <textarea name="hashtags" id="hashtags" rows="2" className="form-control" placeholder={translate("Hashtag")} defaultValue={campaign.hashtags}></textarea>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Image")}</label>
                                                <div className="col-3">
                                                    <img src={campaign.image} alt="" onError={defaultCampaignImage} ref={campaignImageRef} className="img-thumbnail w-100"/>
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <input type="file" id="image" name="image" className="form-control d-none" ref={imageUploadRef} placeholder={translate("Image")} onChange={handleImageChange}/>
                                                    <button type="button" className="btn btn-primary theme-bg-color" onClick={() => imageUploadRef.current.click()}>{translate("Change Frame")}</button>
                                                </div>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Region")}</label>
                                                <select name="region_id" id="region" className="form-control" defaultValue={campaign.region_id}>
                                                    <option value="">--{translate('Select Region')}--</option>
                                                    {
                                                        regions.map((region) => {
                                                            return (<option key={region.id} value={region.id}>{region.name}</option>);
                                                        })

                                                    }
                                                </select>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary mb-4 theme-bg-color">{!loading ? translate("Update") : <Loader size="sm"/>}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default EditCampaign;