import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import userReducer from '../store/user';
import regionsReducer from '../store/regions';
import languageReducer from '../store/languages';
import settingsReducer from '../store/settings';
import api from "./middleware/api";

const persistConfig = {
    key: 'root',
    storage,
}
const rootReducer = combineReducers({
    user: userReducer,
    regions: regionsReducer,
    languages: languageReducer,
    settings: settingsReducer
})
export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: [
        api
    ]
});

export const persistor = persistStore(store);
