import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {changePassword} from "../store/user";
import {translate} from "../utils/function";

const ChangPassword = () => {
    function formSubmit(e) {
        e.preventDefault();
        let {current_password, new_password, new_confirm_password} = Object.fromEntries(new FormData(e.target));
        changePassword(current_password, new_password, new_confirm_password, () => document.getElementById("change-password-form").reset());
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="mb-5">
                        <div className="d-flex justify-content-center">
                            <div className="card p-4 col-11 col-md-6 col-sm-11 login_sec">
                                <h4 className="mb-2 text-center">
                                    <strong>{translate("Change Password")}</strong>
                                </h4>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-10">
                                        <form id="change-password-form" onSubmit={formSubmit}>
                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="current_password">{translate("Current Password")}</label>
                                                <input type="password" id="current_password" name="current_password" className="form-control" placeholder={translate("Current Password")}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="new_password">{translate("New Password")}</label>
                                                <input type="password" id="new_password" name="new_password" className="form-control" placeholder={translate("New Password")}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="new_confirm_password">{translate("New Confirm Password")}</label>
                                                <input type="password" id="new_confirm_password" name="new_confirm_password" className="form-control" placeholder={translate("New Confirm Password")}/>
                                            </div>

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary mb-4 theme-bg-color">{translate("Update")}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default ChangPassword;