import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {useEffect, useState} from "react";
import {defaultCampaignImage, translate} from "../utils/function";
import DataTable from "react-data-table-component";
import {deleteCampaign, getMyCampaigns} from "../store/campaign";
import {FaEdit, FaTrash} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const MyCampaigns = () => {
    let limit = 10;
    let [myCampaigns, setMyCampaigns] = useState();
    const navigate = useNavigate();

    const changePage = (page) => {
        // let offset = limit * page - limit;
        // fetchData(category, limit, offset);
    };
    const columns = [
        {
            name: translate("Title"),
            selector: (row) => `${row.title}`,
            sortable: true,
        },
        {
            name: translate("Description"),
            selector: (row) => (row.description ? row.description : "-"),
            sortable: true,
            wrap: true,
        },
        {
            name: translate("Hashtag"),
            selector: (row) => (row.hashtags ? row.hashtags : "-"),
            sortable: true,
        },
        {
            name: translate("Supporters"),
            selector: (row) => `${row.supporters}`,
            sortable: true,
        },
        {
            name: translate("Image"),
            selector: (row) => <img src={row.image} className="w-50" alt={row.title} onError={defaultCampaignImage}></img>,
            sortable: true,

        },
        {
            name: translate("Action"),
            selector: (row) => <div>
                <button className="btn btn-primary btn-sm theme-bg-color me-2" onClick={() => navigate(`/campaign/edit/${row.id}`)}><FaEdit/></button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteCampaign(row.id)}><FaTrash/></button>
            </div>,
            sortable: true,

        },
    ];

    useEffect(() => {
        getMyCampaigns((response) => {
            setMyCampaigns(response.data);
        });
    }, [])

    const handleDeleteCampaign = (id) => {
        deleteCampaign(id, () => {
            let data = myCampaigns.data.filter(row => row.id !== id);
            setMyCampaigns({...myCampaigns, data})
        });
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <section>
                                <h3 className="mt-2 mb-4"><strong>{translate("My Campaigns")}</strong></h3>
                                <div className="row">
                                    <div className="card">
                                        <div className="card-body custom">
                                            <DataTable
                                                title=""
                                                columns={columns}
                                                data={myCampaigns && myCampaigns.data}
                                                pagination
                                                highlightOnHover
                                                paginationServer
                                                paginationTotalRows={myCampaigns && myCampaigns.total}
                                                paginationPerPage={limit}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                className="dt-center table-responsive"
                                                onChangePage={(page) => changePage(page)}
                                                responsiveLayout="stack" breakpoint="960px"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default MyCampaigns;