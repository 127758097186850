import {TbMessages} from "react-icons/tb";
import {defaultUserImage, translate} from "../utils/function";
import {FiMessageSquare} from "react-icons/fi";
import {BiSend} from "react-icons/bi";
import Comment from "./Comment";
import {useSelector} from "react-redux";
import {selectUser} from "../store/user";
import {useEffect, useRef, useState} from "react";
import {getComment, storeComment} from "../store/campaign";
import Loader from "./layouts/Loader";

const CommentSection = ({campaign}) => {
    const user = useSelector(selectUser);

    const commentRef = useRef(null);
    const [comments, setComments] = useState([]);
    const [commentsPage, setCommentsPage] = useState(1);
    const [loadComments, setLoadComments] = useState(false);

    useEffect(() => {
        setComments(campaign.comments);
    }, [])
    const handleAddComment = () => {
        storeComment(campaign.id, commentRef.current.value, (response) => {
            setComments([response.data, ...comments]);
            commentRef.current.value = '';
        });
    }

    const onCommentUpdate = (data) => {
        setComments(comments.map(comment => (comment.id === data.id) ? {...comment, comment: data.comment} : comment));
    }

    const onCommentDelete = (commentID) => {
        setComments(comments.filter(comment => comment.id !== commentID));
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) <= (e.target.clientHeight + 5);
        if (bottom) {
            setLoadComments(true);
            getComment(campaign.id, (commentsPage + 1), (response) => {
                setCommentsPage(commentsPage + 1);
                setComments([...comments, ...response.data.data])
                setLoadComments(false);
            })
        }
    }
    return (
        <div className="justify-content-center mt-4">
            <div className="card comment_card">
                <div className="d-flex mt-3 mx-3 inner_comment_card">
                    <div className=" col-6 text-start">
                        <TbMessages size={30} className="theme-color"/>
                        <span className="ms-1 h5">{translate("Discussion")}</span>
                    </div>
                    <div className=" col-6 align-items-center justify-content-end">
                        <div className="alert alert-theme p-1 m-0 pe-4">
                            <FiMessageSquare size={20}/>
                            <small className="h6"> {campaign.comments.length + " " + translate("Comments")}</small>
                        </div>
                    </div>
                </div>
                <hr className="card_hr upper_hr"/>
                <div className="card-body">
                    <div className="row align-items-center">
                        {/* <div className="col-2">

                        </div> */}
                        <div className="col-11">
                            <div className="inner_comment">
                                <div className="user_img">
                                    <img src={user.data ? user.data.image : "/"} alt="avatar" className="rounded-circle w-100 img-thumbnail" onError={defaultUserImage}/>
                                </div>
                                <div className="input_comment w-100">
                                    <input type="text" id="addANote" className="form-control" placeholder={`${translate('Add a comment')}...`} ref={commentRef}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 p-0 comment_send_btn">
                            <button className="btn btn-outline-light p-0 border-0" onClick={handleAddComment}>
                                <BiSend className="theme-color" size={34}/>
                            </button>
                        </div>
                    </div>
                </div>
                <hr className="card_hr my-0 upper_hr"/>
                <div className="card-body comment-area" onScroll={handleScroll}>
                    {
                        comments.map((comment) => {
                            return (
                                <Comment key={comment.id} comment={comment} campaignID={campaign.id} onCommentUpdate={onCommentUpdate} onCommentDelete={onCommentDelete}/>
                            );
                        })
                    }
                    {comments.length <= 0 ?
                        <>
                            <TbMessages size={30} className="theme-color mb-1 pe-2"/>
                            <h5>{translate("Be the first one to comment")}</h5>
                        </>
                        : null}

                    {loadComments ? <Loader/> : null}
                </div>
            </div>
        </div>
    )

}

export default CommentSection;