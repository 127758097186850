import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {selectUser, register} from "../store/user";
import {translate} from "../utils/function";
import SocialMediaAuthentication from "../components/SocialMediaAuthentication";
import Loader from "../components/layouts/Loader";


const Register = () => {
    const [data, setData] = useState({
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        gender: null,
        image: null
    });
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUser);
    const [showemail, setShowEmail] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isLogin) {
            navigate(`${process.env.PUBLIC_URL}/`);
        }
    }, [user, navigate])

    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        // let {first_name, last_name, email, password, gender, image} = data;
        // register(first_name, last_name, gender, image, email, password, () => {
        let {email, password,password_confirmation} = data;
        register(email, password,password_confirmation, () => {
            setLoading(false);
        }, () => {
            setLoading(false);
        });
    }

    const handleChange = (event) => {
        let field_name = event.target.name;
        let field_value = event.target.value;
        if (event.target.type === "file") {
            field_value = event.target.files[0];
        }
        setData((values) => ({...values, [field_name]: field_value}));
    };

    const showFielddata = () => {
        setShowEmail(true);
    }

    const handleClick = (e) => {
        e.preventDefault()
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate("/Login")
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="mb-5 card p-3 login_sec">
                        <h4 className="my-4 text-center">
                            <strong>{translate("Register")}</strong>
                        </h4>

                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-9 col-lg-6">

                                <SocialMediaAuthentication/>
                                <p className="theme_para text-center" onClick={() => showFielddata()}>{translate("Sign Up With Email")}</p>
                                {
                                    showemail ? <form onSubmit={formSubmit}>
                                            {/*<div className="form-outline mb-4">*/}
                                            {/*    <label className="form-label" htmlFor="first-name">{translate("First Name")}</label>*/}
                                            {/*    <input type="text" id="first-name" name="first_name" className="form-control" placeholder={translate("First Name")} onChange={handleChange}/>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-outline mb-4">*/}
                                            {/*    <label className="form-label" htmlFor="last-name">{translate("Last Name")}</label>*/}
                                            {/*    <input type="text" id="last-name" name="last_name" className="form-control" placeholder={translate("Last Name")} onChange={handleChange}/>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="gender" className="form-check-label">{translate("Gender")} :</label>*/}
                                            {/*    <div className="form-check-inline ms-2">*/}
                                            {/*        <input className="form-check-input" type="radio" name="gender" id="gender" onChange={handleChange}/>*/}
                                            {/*        <label className="form-check-label ms-1" htmlFor="gender"> {translate("Male")}</label>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="form-check-inline">*/}
                                            {/*        <input className="form-check-input" type="radio" name="gender" id="female" onChange={handleChange}/>*/}
                                            {/*        <label className="form-check-label ms-1" htmlFor="female"> {translate("Female")}</label>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="form-check-inline">*/}
                                            {/*        <input className="form-check-input" type="radio" name="gender" id="other" onChange={handleChange}/>*/}
                                            {/*        <label className="form-check-label ms-1" htmlFor="other"> {translate("Other")}</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-outline mb-4">*/}
                                            {/*    <label className="form-label" htmlFor="image">{translate("Image")}</label>*/}
                                            {/*    <input type="file" id="image" name="image" className="form-control" placeholder={translate("Image")} onChange={handleChange}/>*/}
                                            {/*</div>*/}
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="email">{translate("Email")}</label>
                                                <input type="email" id="email" name="email" className="form-control" placeholder={translate("Email")} onChange={handleChange}/>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Password")}</label>
                                                <input type="password" id="password" name="password" className="form-control" autoComplete="on" placeholder={translate("Password")} onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="password_confirmation">{translate("Password Confirmation")}</label>
                                                <input type="password" id="password_confirmation" name="password_confirmation" className="form-control" placeholder={translate("Password Confirmation")} onChange={handleChange}/>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block mb-4 theme-bg-color">{!loading ? translate("Sign up") : <Loader size="sm"/>}</button>
                                            <div className="already_account">
                                                <p className="text-center">{translate("Already have an Account?")} <Link onClick={(e) => handleClick(e)} className="btn-color fw-bold" to="/">{translate("Login")}</Link></p>
                                            </div>
                                        </form>
                                        : null
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Register;