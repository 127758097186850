import React from "react";

const CanvasContext = React.createContext({
    editor: true,
    selection: false,
    allowTouchScrolling: true,
    setBackground: () => {
    }
});

export default CanvasContext;
