import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {translate} from "../utils/function";
import Header from "../components/layouts/Header";
import {getCollections} from "../store/campaign";
import Footer from "../components/layouts/Footer";
import Slider from "../components/layouts/Slider";
import Loader from "../components/layouts/Loader";
import CampaignList from "../components/CampaignList";

const CollectionDetails = () => {
    const [collection, setCollection] = useState();
    const [loader, setLoader] = useState(true);
    const {slug} = useParams();

    useEffect(() => {
        getCollections({id: slug}, (response) => {
            setLoader(false);
            setCollection(response.data.data[0]);
        });
    }, [])

    return (
        /* Header Started */
        <div>
            <Header/> <Slider/>
            <main className="mt-5">
                <div className="container">
                    {(() => {

                            if (loader) {
                                return (<Loader/>);
                            } else {
                                return (<CampaignList title={translate("Collection") + ":" + collection.name} data={collection.campaigns}/>)
                            }
                        }
                    )()}
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default CollectionDetails;