import {createSelector, createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./apiActions";
import {changePasswordAPI, forgotPasswordAPI, loginRequest, logoutAPI, registerRequest, resetPasswordAPI, socialMediaLoginAPI, updateUserProfileAPI} from "../utils/api";
import {store} from "./configureStore";

const initialState = {
    isLogin: false,
    token: null,
    data: null,
};
const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginSuccess: (user, action) => {
            let {token, user: data} = action.payload;
            user.isLogin = true;
            user.token = token;
            user.data = data;
            //Capitalize the first letter of first_name
            user.data.first_name = data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1)
            return user;
        },
        logoutRequested: (user, action) => {
            user = initialState;
            return user;
        },
        profileUpdated: (user, action) => {
            user.data = action.payload.data;
            return user;
        },
    }
});

export const {loginSuccess, logoutRequested, profileUpdated} = slice.actions;
export default slice.reducer;

// API Calls
export const register = (email, password,password_confirmation, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...registerRequest(email, password,password_confirmation),
        onStart,
        onSuccess,
        onError
    }));
}
export const login = (email, password, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...loginRequest(email, password),
        onSuccessDispatch: loginSuccess.type,
        displayToast: false,
        onStart,
        onSuccess,
        onError
    }));
}

export const socialMediaLogin = (mobile, email, type, firebase_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...socialMediaLoginAPI(mobile, email, type, firebase_id),
        onSuccessDispatch: loginSuccess.type,
        onStart,
        onSuccess,
        onError
    }));
}

export const forgotPassword = (email, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...forgotPasswordAPI(email),
        onStart,
        onSuccess,
        onError
    }));
}

export const resetPassword = (token, email, password, password_confirmation, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...resetPasswordAPI(token, email, password, password_confirmation),
        onStart,
        onSuccess,
        onError
    }));
}

export const logout = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...logoutAPI(),
        onSuccessDispatch: logoutRequested().type,
        onStart,
        onSuccess,
        onError
    }));
}

//export const updateProfile = (onSuccess, onError, onStart) => {
export const updateProfile = (first_name, last_name, gender, mobile, dob, image, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateUserProfileAPI(first_name, last_name, gender, mobile, dob, image),
        onSuccessDispatch: profileUpdated().type,
        onStart,
        onSuccess,
        onError
    }));
}

export const changePassword = (current_password, new_password, new_confirm_password, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...changePasswordAPI(current_password, new_password, new_confirm_password),
        onStart,
        onSuccess,
        onError
    }));
}

// Selector Functions
export const selectUser = createSelector(
    state => state.user,
    user => user
)

export const selectUserData = createSelector(
    state => state.user.data,
    user => user
)