// import {createSelector} from "@reduxjs/toolkit";
import {store} from '../store/configureStore';
import moment from "moment";

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
    return new Promise((resolve) =>
        setTimeout(() => resolve({data: amount}), 500)
    );
}

export function isLogin() {
    return store.getState().user.isLogin;
}

export function defaultCampaignImage(e) {
    e.target.src = process.env.REACT_APP_DEFAULT_CAMPAIGN_IMAGE;
}

export function defaultUserImage(e) {
    e.target.src = process.env.REACT_APP_DEFAULT_USER_IMAGE;
}

export const translate = (label, defaultLabel = null) => {
    /*Set default Label only if you want custom label */
    let langLabel = store.getState().languages.currentLanguageLabels.data[label]
    if (langLabel) {
        return langLabel
    } else {
        return (!defaultLabel) ? label : defaultLabel;
    }
}

export const dateDifferenceFromNow = (date) => {
    let newdate = moment(date);
    let days = moment().diff(newdate, 'days');
    if (days > 30) {
        let months = moment().diff(newdate, 'months');
        if (months > 12) {
            return moment().diff(newdate, 'years') + " " + translate("Year Ago");
        } else if (days === 1) {
            return days + " " + translate("Day Ago")
        }
        return months + " " + translate("Month Ago");
    } else if (days === 0) {
        return translate("Today");
    }
    return days + " " + translate("Days Ago");
}

export const dateDifferenceFromNowInTime = (date) => {
    return moment(date).fromNow();
}

export const showShortText = (text, length, showDots = true) => {
    if (text !== null) {
        if (text.length > length) {
            let trimmedString = text.substr(0, length);
            //re-trim if we are in the middle of a word
            let result = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
            return showDots ? result + " ..." : result;
        } else {
            return text;
        }
    }
    return false;
}