import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {translate} from "../utils/function";
import Header from "../components/layouts/Header";
import {getAllCampaigns} from "../store/campaign";
import Footer from "../components/layouts/Footer";
import Loader from "../components/layouts/Loader";
import CampaignList from "../components/CampaignList";

const Explore = () => {
    const [campaign, setCampaign] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loadCampaigns, setLoadCampaigns] = useState(false);
    const [lastPage, setLastPage] = useState(null);

    const [page, setPage] = useState(1);
    const [searchParams] = useSearchParams();
    const region = searchParams.get('region');
    const sortBy = searchParams.get('sort-by');
    const navigate = useNavigate();

    useEffect(() => {
        getAllCampaigns({region_id: region, type: sortBy}, (response) => {
            setLoader(false);
            setCampaign(response.data.data);
            setLastPage(response.data.last_page);
        }, () => {
            navigate("/");
        });
    }, [])
    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 50) {
            // you're at the bottom of the page
            if (lastPage !== page) {
                setLoadCampaigns(true);
                getAllCampaigns({region_id: region, page: (page + 1)}, (response) => {
                    setPage(page + 1);
                    setCampaign(campaign.concat(response.data.data))
                    setLoadCampaigns(false);
                })
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener("scroll", handleScroll, true);
    }, [handleScroll])

    const onSortByChange = (sortBy) => {
        // setLoader(true);
        getAllCampaigns({region_id: region, type: sortBy}, (response) => {
            setLoader(false);
            setCampaign(response.data.data);
        });
    }

    return (
        /* Header Started */
        <div onScroll={handleScroll}>
            <Header/>
            <main className="my-5">
                <div className="container">
                    {(() => {
                            if (loader) {
                                return (<div className="text-center"><Loader/></div>);
                            } else {
                                return (<CampaignList title={translate("Explore")} data={campaign} onSortByChange={onSortByChange} loading={loadCampaigns}/>)
                            }
                        }
                    )()}
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Explore;