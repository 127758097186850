import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {selectUserData, updateProfile} from "../store/user";
import {useSelector} from "react-redux";
import {defaultUserImage, translate} from "../utils/function";
import Loader from "../components/layouts/Loader";
import {useState} from "react";

const Login = () => {
    let user = useSelector(selectUserData);
    const [loading, setLoading] = useState(false);

    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let {first_name, last_name, gender, mobile, dob, image} = Object.fromEntries(new FormData(e.target));
        updateProfile(first_name, last_name, gender, mobile, dob, image, () => {
            setLoading(false);
        }, () => {
            setLoading(false);
        });
    }

    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="mb-5">
                        <div className="d-flex justify-content-center">
                            <div className="card p-4 col-11 col-md-6 col-sm-11 login_sec">
                                <h4 className="mb-2 text-center">
                                    <strong>{translate("Profile")}</strong>
                                </h4>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-10">
                                        <form onSubmit={formSubmit}>
                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="form3Example3">{translate("First Name")}</label>
                                                <input type="text" id="first_name" name="first_name" className="form-control" placeholder={translate("First Name")} defaultValue={user.first_name}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Last Name")}</label>
                                                <input type="text" id="last_name" name="last_name" className="form-control" placeholder={translate("Last Name")} defaultValue={user.first_name}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label me-3" htmlFor="gender">{translate("Gender")} :</label>

                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" id="male" value="Male" defaultChecked={user.gender === "Male" ? true : false}/>
                                                    <label className="form-check-label" htmlFor="male">{translate("Male")}</label>
                                                </div>

                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" id="female" value="Female"/>
                                                    <label className="form-check-label" htmlFor="female">{translate("Female")}</label>
                                                </div>

                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="gender" id="other" value="Other"/>
                                                    <label className="form-check-label" htmlFor="other">{translate("Other")}</label>
                                                </div>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Mobile")}</label>
                                                <input type="number" id="mobile" name="mobile" className="form-control" placeholder={translate("Mobile")} defaultValue={user.mobile}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("DOB")}</label>
                                                <input type="date" id="dob" name="dob" className="form-control" placeholder={translate("DOB")} defaultValue={user.dob}/>
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="form-label" htmlFor="form3Example4">{translate("Image")}</label>
                                                <input type="file" id="image" name="image" className="form-control" placeholder={translate("Image")}/>
                                            </div>
                                            <div className="w-25">
                                                <img src={user.image} alt={user.full_name} className="w-50 rounded-circle" onError={defaultUserImage}/>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary mb-4 theme-bg-color">{!loading ? translate("Update") : <Loader size="sm"/>}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Login;