import {translate} from "../../utils/function";
import React from "react";
import {Spinner} from "react-bootstrap";

const Loader = ({size}) => {
    return (
        <Spinner animation="border" role="status" size={size ? size : "md"}>
            <span className="visually-hidden">{translate('Loading')}</span>
        </Spinner>
    );
}

export default Loader;