/*
 * This file will list all the APIs
 * @params
 * url : \login - relative url
 * method : GET / POST / PUT / DELETE
 * data: { email,password }
 * headers: { Accept: "application/json" }
 * authorizationHeader : true / false , default : true --> if Authorisation Header should be set in request or not
 */
export const registerRequest = (email, password,password_confirmation) => {
    let data = new FormData();
    // data.append("first_name", first_name);
    // data.append("last_name", last_name);
    // data.append("gender", gender);
    // data.append("image", image);
    data.append("email", email);
    data.append("password", password);
    data.append("password_confirmation", password_confirmation);
    return {
        url: "/register",
        method: "POST",
        data: data,
    }
};
export const loginRequest = (email, password) => {
    return {
        url: "/login",
        method: "POST",
        data: {
            email,
            password
        },
    }
};

export const socialMediaLoginAPI = (mobile, email, type, firebase_id) => {
    return {
        url: "/social-login",
        method: "POST",
        data: {
            mobile,
            email,
            type,
            firebase_id
        },
    }
};

export const forgotPasswordAPI = (email) => {
    return {
        url: "/forgot-password",
        method: "POST",
        data: {
            email
        },
    }
};

export const resetPasswordAPI = (token, email, password, password_confirmation) => {
    return {
        url: "/reset-password",
        method: "POST",
        data: {
            token,
            email,
            password,
            password_confirmation
        },
    }
};

export const logoutAPI = () => {
    return {
        url: "/logout",
        method: "POST",
        authorizationHeader: true,
    }
};

export const createCampaignAPI = (title, description, hashtags, image, region_id, slug) => {
    // Formdata is used to upload files on server
    let data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("hashtags", hashtags);
    data.append("image", image);
    data.append("region_id", region_id);
    data.append("slug", slug);

    return {
        url: "/campaign/create",
        method: "POST",
        authorizationHeader: true,
        data
    }
};

export const getMyCampaignsAPI = () => {
    return {
        url: "/campaign/my",
        method: "GET",
        authorizationHeader: true,
    }
};
export const getAllCampaignsAPI = (search, slug, id, region_id, language_id, type, page, limit) => {
    return {
        url: "/campaign",
        method: "GET",
        params: {
            search,
            slug,
            id,
            region_id,
            language_id,
            type,
            page,
            limit
        },
        authorizationHeader: false,
    }
};

export const deleteCampaignAPI = (id) => {
    return {
        url: "/campaign/delete",
        method: "POST",
        params: {
            id,
        },
        authorizationHeader: true,
    }
};

export const updateCampaignAPI = (id, title, description, hashtags, image, region_id) => {
    // Formdata is used to upload files on server
    let data = new FormData();
    data.append("id", id);
    data.append("title", title);
    data.append("description", description);
    data.append("hashtags", hashtags);
    data.append("image", image);
    data.append("region_id", region_id);

    return {
        url: "/campaign/update",
        method: "POST",
        authorizationHeader: true,
        data
    }
};


export const supportCampaignAPI = (id) => {
    return {
        url: "/campaign/support",
        method: "POST",
        data: {
            id
        },
        authorizationHeader: true,
    }
};

export const checkCampaignURLAvailabilityAPI = (link) => {
    return {
        url: "/campaign/url-availability",
        method: "GET",
        params: {
            link
        },
        authorizationHeader: false,
    }
};

export const getRegionsAPI = () => {
    return {
        url: "/regions",
        method: "GET",
        authorizationHeader: false,
    }
};

export const getLanguagesAPI = () => {
    return {
        url: "/languages",
        method: "GET",
        authorizationHeader: false,
    }
};

export const getLanguageLabelAPI = (code) => {
    return {
        url: "/language-labels",
        method: "GET",
        params: {
            code
        },
        authorizationHeader: false,
    }
};

export const getCommentAPI = (campaign_id, page = 1) => {
    return {
        url: "/comment",
        method: "GET",
        params: {
            campaign_id,
            page
        },
        authorizationHeader: true,
    }
};
export const storeCommentAPI = (campaign_id, comment) => {
    return {
        url: "/comment/store",
        method: "POSt",
        data: {
            campaign_id,
            comment
        },
        authorizationHeader: true,
    }
};

export const updateCommentAPI = (id, comment) => {
    return {
        url: "/comment/update",
        method: "POSt",
        data: {
            id,
            comment
        },
        authorizationHeader: true,
    }
};

export const deleteCommentAPI = (id) => {
    return {
        url: "/comment/delete",
        method: "POSt",
        data: {
            id,
        },
        authorizationHeader: true,
    }
};
export const getCollectionsAPI = (search, id, region_id, page, limit) => {
    return {
        url: "/collections",
        method: "GET",
        params: {
            search,
            id,
            region_id,
            page,
            limit
        },
        authorizationHeader: false,
    }
};

export const getSettingsAPI = (type) => {
    return {
        url: "/settings",
        method: "GET",
        params: {
            type
        },
        authorizationHeader: false,
    }
};

export const updateUserProfileAPI = (first_name, last_name, gender, mobile, dob, image) => {
    let data = new FormData();
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('gender', gender);
    data.append('mobile', mobile);
    data.append('dob', dob);
    if (image) {
        data.append('image', image);
    }
    return {
        url: "/update-profile",
        method: "POST",
        data,
        authorizationHeader: true,
    }
};

export const changePasswordAPI = (current_password, new_password, new_confirm_password) => {
    return {
        url: "/change-password",
        method: "POST",
        data: {
            current_password,
            new_password,
            new_confirm_password
        },
        authorizationHeader: true,
    }
};

export const reportAPI = (type, type_id, reason, sub_reason, description, email) => {
    return {
        url: "/report",
        method: "POST",
        data: {
            type,
            type_id,
            reason,
            sub_reason,
            description,
            email
        },
        authorizationHeader: true,
    }
};
