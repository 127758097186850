// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {translate} from "../utils/function";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import Campaign from "./Campaign";
import { IoArrowForward } from 'react-icons/io5';


const CampaignSlider = ({title, data, regionID}) => {
    const navigate = useNavigate();
    return (
        data ?
            <section className='trending_Sec'>
                <div className="row mb-2">
                    <div className="trending_title_data">
                        <div className="text-start">
                            <h4><strong className='trending_title'>{title}</strong></h4>
                        </div>
                        <div className="text-end trending_view_all">
                            <Link className="btn btn-primary" to={`/explore${regionID ? "?region=" + regionID : ''}`}>{translate("View All")}<IoArrowForward/></Link>
                        </div>
                    </div>
                </div>
                <hr className='card_hr'/>

                <>
                    <Swiper modules={[Pagination]} spaceBetween={25} pagination={{clickable: true}} breakpoints={{
                        0: {
                            slidesPerView: 1.2,
                            spaceBetween: 10,
                        },
                        376: {
                            slidesPerView: 1.5,
                            spaceBetween: 10,
                        },
                        576: {
                            slidesPerView: 2.5,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 10,
                        },
                        992: {
                            slidesPerView: 3.5,
                            spaceBetween: 15,
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },

                    }}>
                        {data.map((row) => {
                            return <SwiperSlide key={row.id} role="button" onClick={() => navigate(`/${row.slug}`)}>
                                <Campaign data={row}/>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </>
            </section>
            : null
    );
}

export default CampaignSlider;