import {createSelector, createSlice} from "@reduxjs/toolkit";
import moment from "moment";

import {apiCallBegan} from "./apiActions";
import {getRegionsAPI} from "../utils/api";
import {store} from "./configureStore";

let initialState = {
    list: [],
    loading: false,
    lastFetch: null,
    currentRegion: {
        id: null,
        name: null
    },
};
const slice = createSlice({
    name: 'regions',
    initialState,
    reducers: {
        regionsRequested: (regions, action) => {
            regions.loading = true;
        },
        regionsReceived: (regions, action) => {
            let payload = action.payload.data;
            // Add All as region on top of object
            payload.unshift({
                id: null,
                name: "All",
            })
            regions.list = payload;
            regions.loading = false;
            regions.lastFetch = Date.now();
        },
        regionsRequestFailed: (regions, action) => {
            regions.loading = false;
        },
        regionChanged: (regions, action) => {
            regions.currentRegion.id = action.payload.id;
            regions.currentRegion.name = action.payload.name;
        },
    }
});

export const {regionsRequested, regionsReceived, regionsRequestFailed, regionChanged} = slice.actions;
export default slice.reducer;

// API Calls
export const loadRegions = (id = null, search = null, onSuccess, onError, onStart) => {
    const {lastFetch} = store.getState().regions;
    const diffInMinutes = moment().diff(moment(lastFetch), 'minutes');
    // If API data is fetched within last 10 minutes then don't call the API again
    if (diffInMinutes < 10) return false;
    store.dispatch(apiCallBegan({
        ...getRegionsAPI(id, search),
        displayToast: false,
        onStartDispatch: regionsRequested.type,
        onSuccessDispatch: regionsReceived.type,
        onErrorDispatch: regionsRequestFailed.type,
        onStart,
        onSuccess,
        onError
    }))
};

export const setCurrentRegion = (id, name) => {
    store.dispatch(regionChanged({
        id,
        name
    }));
};

// Selector Functions
export const selectRegions = createSelector(
    state => state.regions,
    regions => regions.list,
)

export const selectCurrentRegion = createSelector(
    state => state.regions.currentRegion,
    regions => regions,
)