import Header from "../components/layouts/Header";
import CampaignSlider from "../components/CampaignSlider";
import Footer from "../components/layouts/Footer";
import Slider from "../components/layouts/Slider";
import {useEffect, useState} from "react";
import {selectCurrentRegion} from "../store/regions";
import {useSelector} from "react-redux";
import {loadLanguageLabels, selectCurrentLanguage} from "../store/languages";
import {translate} from "../utils/function";
import {getAllCampaigns, getCollections} from "../store/campaign";
import CollectionSlider from "../components/CollectionSlider";

const Home = () => {
    const currentRegion = useSelector(selectCurrentRegion);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [regionCampaigns, setRegionCampaigns] = useState();
    const [AllRegionCampaigns, setAllRegionCampaigns] = useState();
    const [collections, setCollections] = useState();

    useEffect(() => {
        loadLanguageLabels(currentLanguage.code);
    }, [currentLanguage])

    // This method will be called when region will change
    useEffect(() => {
        //Fetch Region wise Campaign
        if (currentRegion.id) {
            getAllCampaigns({region_id: currentRegion.id, limit: 10}, (response) => setRegionCampaigns(response.data.data));
        }

        getAllCampaigns({limit: 10}, (response) => setAllRegionCampaigns(response.data.data));
        getCollections({region_id: currentRegion.id, limit: 10}, (response) => setCollections(response.data.data), () => setCollections(null));
    }, [currentRegion])
    return (
        /* Header Started */
        <div>
            <Header />
            <Slider />
            <main className="main_sec">
                <div className="container">
                    <CollectionSlider title={translate('Featured Collection')} data={collections}/>
                    {collections ? <hr className="my-3"/> : null}

                    <CampaignSlider title={translate("Trending in") + " " + currentRegion.name} data={regionCampaigns} regionID={currentRegion.id}/>
                    <CampaignSlider title={translate("Trending All Over")} data={AllRegionCampaigns}/>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Home;