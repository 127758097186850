import React from "react";
import {translate} from "../../utils/function";
import {useSelector} from "react-redux";
import {selectSettings} from "../../store/settings";
import { IoArrowForward } from "react-icons/io5";
import {useNavigate} from "react-router-dom";

const Slider = () => {
    const settings = useSelector(selectSettings);
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div id="intro" className="bg-image shadow-1-strong slider_sec">
                {/*<div className="mask slider-image" style={{"backgroundImage": "url('" + process.env.PUBLIC_URL + "/assets/img/slider.png')"}}>*/}
                <div className="mask slider-image" style={{"backgroundImage": "url('" + settings.slider_image + "')"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center">
                                <div className="slider-frame-left">
                                    <img src={settings.slider_left_small_image} className="header-frame-left" alt={"frame 1"}/>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-start">
                                <div className="text-white slider_content">
                                    <h1><b>{translate("Slider Title", "Did you frame it?")}</b></h1>
                                    <h5>{translate("Slider Description", "Find & Support your favourite campaign")}</h5>
                                    <button className="btn btn-primary btn-lg theme-bg-color Explore_btn" rel="nofollow" onClick={() => navigate("/Explore")}>{translate("Explore")}<IoArrowForward/></button>
                                </div>
                            </div>
                            {/*<div className="col-3">*/}
                            {/*    <div className="slider-frame-right">*/}
                            {/*        <img src={`${process.env.PUBLIC_URL}/assets/img/hand.png`} className="header-frame-right" alt={"frame 2"}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Slider;