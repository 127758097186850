import {Swiper, SwiperSlide} from "swiper/react";
import {translate} from "../utils/function";
import {Link} from "react-router-dom";
import {Pagination} from "swiper";
import {useSelector} from "react-redux";
import {selectSettings} from "../store/settings";

const CollectionSlider = ({title, data}) => {
    const settings = useSelector(selectSettings);
    return (
        data ?
            <section>
                <div className="row">
                    <div className="text-start col-8">
                        <h4><strong>{title}</strong></h4>
                    </div>
                    <div className="text-end col-4">
                        <Link to="/collection">{translate("View All")}</Link>
                    </div>
                </div>
                <div className="row">
                    <Swiper modules={[Pagination]} spaceBetween={25} pagination={{clickable: true}} breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 100,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}>
                        {
                            data.map((row) => {
                                return <SwiperSlide key={row.id} className="mb-4">
                                    <div className="bg-image hover-overlay ripple m-3" data-mdb-ripple-color="light">
                                        <Link to={`/collection/${row.id}`}> <img src={settings.collection_image} className="img-fluid  collection-image" title={row.name} alt={row.name}/>
                                            <div className="collection-title text-white col-md-3"><h3>{row.name}</h3></div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                </div>
            </section>
            : ''
    );
}

export default CollectionSlider;