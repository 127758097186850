import {dateDifferenceFromNowInTime, defaultUserImage, isLogin, translate} from "../utils/function";
import {FaRegFlag} from "react-icons/fa";
import {deleteComment, reportComment, updateComment} from "../store/campaign";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectUser} from "../store/user";
import {Button, Modal, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoEllipsisVertical } from "react-icons/io5";

const Comment = ({comment, onCommentUpdate, onCommentDelete}) => {
    const [showModal, setShowModal] = useState(false);
    const [showReportDropDown, setShowReportDropDown] = useState();
    const [modalStep, setModalStep] = useState(1);
    const [data, setData] = useState();
    const [reportLoader, setReportLoader] = useState(false);


    const user = useSelector(selectUser);
    const [updateMode, setUpdateMode] = useState(false);
    const handleCommentUpdate = (e) => {
        e.preventDefault();
        let data = Object.fromEntries(new FormData(e.target));
        updateComment(data.id, data.comment, (response) => {
            onCommentUpdate(response.data);
            setUpdateMode(false);
        })
    }
    const handleCommentDelete = (commentID) => {
        deleteComment(commentID, () => {
            onCommentDelete(commentID);
        });
    }

    const handleReportReasonChange = (e) => {
        setShowReportDropDown(e.target.value);
        handleChange(e);
    }

    const closeReportModal = () => {
        setShowReportDropDown("");
        setShowModal(false);
        setModalStep(1);
    }
    const handleChange = (event) => {
        const field_name = event.target.name;
        const field_value = event.target.value;
        setData((values) => ({...values, [field_name]: field_value}));
    };

    const validateFirstStep = () => {
        if (!data || !data.reason) {
            toast.error(translate("Please Select the Reason first"), {position: "bottom-center"})
            return false;
        }
        if (data.reason !== "Spam" && !data.sub_reason) {
            toast.error(translate("Please Select the Sub Reason first"), {position: "bottom-center"})
            return false;
        }
        setModalStep(2)
    }

    const validateSecondStep = () => {
        if (!data || !data.email) {
            toast.error(translate("Email is required"), {position: "bottom-center"})
            return false;
        }
        setReportLoader(true);
        reportComment(comment.id, data.reason, data.sub_reason, data.description, data.email, () => {
            setModalStep(3);
            setReportLoader(false);
            setData(null);
        })

    }

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="d-flex align-items-center row">
                    <div className="col-3 col-sm-2 col-md-2">
                        <img src={comment.user.image} alt="avatar" className="rounded-circle w-100 img-thumbnail" onError={defaultUserImage}/>
                    </div>
                    <div className="col-5 col-md-7 col-sm-7 p-0 text-start">
                        <b className="text-capitalize">{comment.user.first_name}</b>
                    </div>


                    <div className="col-4 col-md-3 col-sm-3  text-end">
                        <div className="dots-menu">
                            <Link data-bs-toggle="dropdown"><IoEllipsisVertical/></Link>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link href="#">
                                    {
                                        isLogin() && user.data.id === comment.user.id ?
                                            <>
                                                <BsPencil size={17} className=" me-2" role="button" onClick={() => setUpdateMode(true)}/>
                                                <BsTrash size={17} className=" me-2" role="button" onClick={() => handleCommentDelete(comment.id)}/>
                                            </>
                                        : null
                                    }
                                    {
                                        isLogin() ? <FaRegFlag size={17} className="text-reset" role="button" onClick={() => setShowModal(true)} title="Report"/> : null
                                    }
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-12 text-start">
                        {
                            updateMode ?
                                <>
                                    <form onSubmit={handleCommentUpdate}>
                                        <input type="hidden" name="id" defaultValue={comment.id}></input>
                                        <textarea name="comment" rows="3" className="form-control" defaultValue={comment.comment}></textarea>
                                        <button type="submit" className="btn theme-bg-color btn-primary mt-2">{translate('Edit')}</button>
                                        <button type="button" className="btn btn-light mt-2 ms-2 ps-3 pe-3" onClick={() => setUpdateMode(false)}>{translate('Cancel')}</button>
                                    </form>
                                </>
                                : <p className="mb-0 mt-1 ms-2 me-2 text-justify">{comment.comment}</p>
                        }
                    </div>
                </div>
                <div className="text-start mt-2">
                    <small className="small mb-0 ms-2 text-black-50">{dateDifferenceFromNowInTime(comment.created_at)}</small>
                    {/*<small className="small mb-0 ms-4 theme-color">1 Like <BiLike size={15} className="mt-n1"/></small>*/}
                    {/*<small className="small mb-0 ms-4">Reply</small>*/}
                </div>
            </div>
            <Modal show={showModal} onHide={() => closeReportModal()} className="vertical-center-modal">
                <form encType="multipart/form-data">
                    {(() => {
                        if (modalStep === 1) {
                            return (
                                <>
                                    <Modal.Body>
                                        <h4 className="text-center font-weight-bold mt-3">{translate("Why are you reporting this ?")}</h4>
                                        <div className="card custom border mt-3">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="form-check-inline ms-2">
                                                        <input className="form-check-input" type="radio" name="reason" id="Abusive or Harmful" value="Abusive or Harmful" onChange={handleReportReasonChange} required={true}/>
                                                        <label className="form-check-label ms-2" htmlFor="Abusive or Harmful"> {translate("Abusive or Harmful")}</label>
                                                    </div>
                                                </div>

                                                <div className={`form-group mt-2 ms-4 ${(showReportDropDown === "Abusive or Harmful") ? 'd-block' : 'd-none'}`}>
                                                    <select name="sub_reason" id="" className="form-select" required={true} onChange={handleChange}>
                                                        <option value="">{translate("Choose One")}</option>
                                                        <option value="Harassment">{translate("Harassment")}</option>
                                                        <option value="Terrorism">{translate("Terrorism")}</option>
                                                        <option value="Violence">{translate("Violence")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card custom border mt-3">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="form-check-inline ms-2">
                                                        <input className="form-check-input" type="radio" name="reason" id="False Information" value="False Information" onChange={handleReportReasonChange} required={true}/>
                                                        <label className="form-check-label ms-2" htmlFor="False Information"> {translate("False Information")}</label>
                                                    </div>
                                                </div>
                                                <div className={`form-group mt-2 ms-4 ${(showReportDropDown === "False Information") ? 'd-block' : 'd-none'}`}>
                                                    <select name="sub_reason" id="" className="form-select" required={true} onChange={handleChange}>
                                                        <option value="">{translate("Choose One")}</option>
                                                        <option value="Health">{translate("Health")}</option>
                                                        <option value="Politics">{translate("Politics")}</option>
                                                        <option value="Social Issue">{translate("Social Issue")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card custom border mt-3">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="form-check-inline ms-2">
                                                        <input className="form-check-input" type="radio" name="reason" id="Hate Speech" value="Hate Speech" onChange={handleReportReasonChange} required={true}/>
                                                        <label className="form-check-label ms-2" htmlFor="Hate Speech"> {translate("Hate Speech")}</label>
                                                    </div>
                                                </div>
                                                <div className={`form-group mt-2 ms-4 ${(showReportDropDown === "Hate Speech") ? 'd-block' : 'd-none'}`}>
                                                    <select name="sub_reason" id="" className="form-select" required={true} onChange={handleChange}>
                                                        <option value="">{translate("Choose One")}</option>
                                                        <option value="Disability or disease">{translate("Disability or disease")}</option>
                                                        <option value="Race or ethnicity">{translate("Race or ethnicity")}</option>
                                                        <option value="Religious affiliation">{translate("Religious affiliation")}</option>
                                                        <option value="Sexual orientation">{translate("Sexual orientation")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card custom border mt-3">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="form-check-inline ms-2">
                                                        <input className="form-check-input" type="radio" name="reason" id="Sensitive Content" value="Sensitive Content" onChange={handleReportReasonChange} required={true}/>
                                                        <label className="form-check-label ms-2" htmlFor="Sensitive Content"> {translate("Sensitive Content")}</label>
                                                    </div>
                                                </div>
                                                <div className={`form-group mt-2 ms-4 ${(showReportDropDown === "Sensitive Content") ? 'd-block' : 'd-none'}`}>

                                                    <select name="sub_reason" id="" className="form-select" required={true} onChange={handleChange}>
                                                        <option value="">{translate("Choose One")}</option>
                                                        <option value="Nudity">{translate("Nudity")}</option>
                                                        <option value="Suicide or Self-harm">{translate("Suicide or Self-harm")}</option>
                                                        <option value="Vulgar Language">{translate("Vulgar Language")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card custom border mt-3">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="form-check-inline ms-2">
                                                        <input className="form-check-input" type="radio" name="reason" id="Spam" value="Spam" onChange={handleReportReasonChange} required={true}/>
                                                        <label className="form-check-label ms-2" htmlFor="Spam"> {translate("Spam")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button type="button" variant="primary" className="theme-bg-color" onClick={validateFirstStep}>{translate("Next")}</Button>
                                    </Modal.Footer>
                                </>
                            );
                        } else if (modalStep === 2) {
                            return (
                                <>
                                    <Modal.Body>
                                        <div className="form-group mb-4">
                                            <label className="form-label text-dark" htmlFor="description">{translate("Tell us more")}</label>
                                            <textarea id="description" name="description" className="form-control" placeholder={translate("Short Description")} onChange={handleChange}/>
                                        </div>

                                        <div className="form-group mb-4">
                                            <label className="form-label text-dark" htmlFor="email">{translate("Email")}</label>
                                            <input type="email" id="email" name="email" className="form-control" placeholder={translate("Email")} onChange={handleChange}/>
                                        </div>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button type="button" variant="light" onClick={() => setModalStep(1)}>{translate("Back")}</Button>
                                        <Button type="button" variant="primary" className="theme-bg-color" onClick={validateSecondStep}>{!reportLoader ? translate("Submit") : <Spinner animation="border" size="sm"/>}</Button>
                                    </Modal.Footer>
                                </>
                            );
                        } else if (modalStep === 3) {
                            return (
                                <>
                                    <Modal.Body>
                                        <div className="text-center">
                                            <h4>{translate("We have received your report")}</h4>
                                            <h6 className="mt-3">{translate("Thank you for your report. Please allow us to investigate your report.")}</h6>
                                            <Button type="button" variant="primary" className="theme-bg-color btn-block mt-5 rounded-4" onClick={closeReportModal}><h6>{translate("Okay")}</h6></Button>
                                        </div>
                                    </Modal.Body>
                                </>
                            );
                        }
                    })()}

                </form>
            </Modal>
        </div>
    );
}

export default Comment;