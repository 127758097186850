import {createSelector, createSlice} from "@reduxjs/toolkit";
import {apiCallBegan} from "./apiActions";
import {getSettingsAPI} from "../utils/api";
import {store} from "./configureStore";
import moment from "moment/moment";

const initialState = {
    lastFetch: null
};
const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        settingsFetched: (settings, action) => {
            settings = action.payload.data;
            settings.lastFetch = Date.now();
            return settings;
        },
    }
});

export const {settingsFetched} = slice.actions;
export default slice.reducer;

// API Calls
export const fetchSettings = (type, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getSettingsAPI(type),
        displayToast: false,
        onStart,
        onSuccess,
        onError
    }));
}


export const getSettings = (onSuccess, onError, onStart) => {
    const {lastFetch} = store.getState().settings;
    const diffInMinutes = moment().diff(moment(lastFetch), 'minutes');
    // If API data is fetched within last 10 minutes then don't call the API again
    if (diffInMinutes < 10) return false;
    store.dispatch(apiCallBegan({
        ...getSettingsAPI('web_settings'),
        displayToast: false,
        onSuccessDispatch: settingsFetched.type,
        onStart,
        onSuccess,
        onError
    }));
}

// Selector Functions
export const selectSettings = createSelector(
    state => state.settings,
    image => image,
)