import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {useState} from "react";

import {forgotPassword} from "../store/user";
import {translate} from "../utils/function";
import Loader from "../components/layouts/Loader";

const Login = () => {
    const [data, setData] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);

    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        forgotPassword(data.email, () => {
            setLoading(false);
        }, () => {
            setLoading(false);
        });
    }

    const handleChange = (event) => {
        const field_name = event.target.name;
        const field_value = event.target.value;
        if (field_name === "mobile" && event.target.value.length > 16) {
            event.target.value = field_value.slice(0, event.target.maxLength);
            return false;
        }
        setData((values) => ({...values, [field_name]: field_value}));
    };
    return (
        /* Header Started */
        <div>

            <Header/>
            <main className="mt-5">
                <div className="container">
                    <section className="card mb-5 p-3 login_sec">
                        <h4 className="mt-3 mb-1 text-center">
                            <strong>{translate("Forgot Password")}</strong>
                        </h4>

                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-6">
                                <form onSubmit={formSubmit}>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form3Example3">{translate("Email")}</label>
                                        <input type="email" id="email" name="email" className="form-control" placeholder={translate("Email")} onChange={handleChange}/>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block mb-4 theme-bg-color">{!loading ? translate("Submit") : <Loader size="sm"/>}</button>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Login;